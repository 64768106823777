var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"rqlindex-conitaner",attrs:{"id":"rsindex"}},[(_vm.maskbok)?_c('div',{staticClass:"maskbok"}):_vm._e(),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.projectData.project)+" ")]),_c('div',{staticClass:"main flex"},[_c('div',{staticClass:"left_all"},[_c('div',{staticClass:"left_up flex"},[_c('div',{staticClass:"lable1"},[_vm._v("煤气管道")]),_c('div',[_c('div',{staticClass:"famen1 jiange1"},[_c('img',{attrs:{"src":_vm._f("typeFifter")(_vm.infoList.V_SOUREC.KX_01_B),"alt":"","ondragstart":"return false;"}})]),_c('div',{staticClass:"anfa flex"},[_c('div',{staticClass:"button leftx1",style:({
                  background:
                    _vm.infoList.V_GONGGONG__p__ZGXK01 &&
                    !_vm.infoList.V_GONGGONG__p__ZGXK01.TS
                      ? '#2AFC30'
                      : 'red',
                }),on:{"click":function($event){_vm.infoList.V_GONGGONG__p__ZGXK01
                    ? _vm.toDetail(
                        1,
                        'ZGXK01',
                        'V_GONGGONG__p__ZGXK01',
                        '',
                        '煤气总管压力先控'
                      )
                    : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"button leftx2",style:({
                  background:
                    _vm.infoList.V_GONGGONG__p__MAN1 &&
                    _vm.infoList.V_GONGGONG__p__MAN1.RM == 1
                      ? '#2AFC30'
                      : 'red',
                }),on:{"click":function($event){_vm.infoList.V_GONGGONG__p__MAN1
                    ? _vm.toCompon(
                        0,
                        'AV',
                        'V_GONGGONG__p__MAN1',
                        'MAN1_AV_WF',
                        '煤气总管压力调节阀'
                      )
                    : ''}}},[_vm._v(" A ")]),_c('div',{staticClass:"lefttext lefttextje"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                      2,
                      'KX_01_B',
                      'V_SOUREC',
                      'KX_01_B_WF',
                      '煤气总管阀调节DCS'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.V_SOUREC.KX_01_B,
                      'KX_01_B',
                      'V_SOUREC',
                      'KX_01_B_WF'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.KX_01_B)+" % ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                      2,
                      'FVI_01_B',
                      'V_SOUREC',
                      'FVI_01_B_WF',
                      '煤气总管阀位反馈'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.V_SOUREC.FVI_01_B,
                      'FVI_01_B',
                      'V_SOUREC',
                      'FVI_01_B_WF'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.FVI_01_B)+" % ")])])])]),_c('div',{staticClass:"lefttext1"},[_c('div',{staticClass:"hjg",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'FT_01_B',
                  'V_SOUREC',
                  'FT_01_B_WF',
                  '煤气总管流量'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.V_SOUREC.FT_01_B,
                  'FT_01_B',
                  'V_SOUREC',
                  'FT_01_B_WF'
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.FT_01_B)+" m³/h ")]),_c('div',{staticClass:"hjg",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'PIC_01_B',
                  'V_SOUREC',
                  'PIC_01_B_WF',
                  '煤气总管压力'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.V_SOUREC.PIC_01_B,
                  'PIC_01_B',
                  'V_SOUREC',
                  'PIC_01_B_WF'
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.PIC_01_B)+" KPa ")]),_c('div',{staticClass:"hjg",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'TE_02_B',
                  'V_SOUREC',
                  'TE_02_B_WF',
                  '煤气总管预热后温度'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.V_SOUREC.TE_02_B,
                  'TE_02_B',
                  'V_SOUREC',
                  'TE_02_B_WF'
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.TE_02_B)+" ℃ ")])]),_c('div',[_vm._m(0),_c('div',[_c('div',{staticClass:"gdpart flex"},[_c('div',{staticClass:"gd1"},[_c('div',{staticClass:"htwd"},[_vm._v("1号炉")]),_c('div',{staticClass:"htwdtitle"},[_c('div',{staticClass:"jiange3",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'TE_11_B1',
                          'V_SOUREC',
                          'TE_11_B1_WF',
                          '1#热风炉拱顶温度1'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.V_SOUREC.TE_11_B1,
                          'TE_11_B1',
                          'V_SOUREC',
                          'TE_11_B1_WF'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.TE_11_B1)+" ℃ ")]),_c('div',{staticClass:"jiange3",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'DTXR_B1',
                          'V_RCL1',
                          'DTXR_B1_WF',
                          '1#动态蓄热能力'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.V_RCL1.DTXR_B1,
                          'DTXR_B1',
                          'V_RCL1',
                          'DTXR_B1_WF'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_RCL1.DTXR_B1)+" % ")])])]),_c('div',{staticClass:"gd2"},[_c('div',{staticClass:"htwd"},[_vm._v("2号炉")]),_c('div',{staticClass:"htwdtitle"},[_c('div',{staticClass:"jiange3",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'TE_11_B2',
                          'V_SOUREC',
                          'TE_11_B2_WF',
                          '2#热风炉拱顶温度1'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.V_SOUREC.TE_11_B2,
                          'TE_11_B2',
                          'V_SOUREC',
                          'TE_11_B2_WF'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.TE_11_B2)+" ℃ ")]),_c('div',{staticClass:"jiange3",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'DTXR_B2',
                          'V_RCL2',
                          'DTXR_B2_WF',
                          '2#动态蓄热能力'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.V_RCL2.DTXR_B2,
                          'DTXR_B2',
                          'V_RCL2',
                          'DTXR_B2_WF'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_RCL2.DTXR_B2)+" % ")])])]),_c('div',{staticClass:"gd3"},[_c('div',{staticClass:"htwd"},[_vm._v("3号炉")]),_c('div',{staticClass:"htwdtitle"},[_c('div',{staticClass:"jiange3",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'TE_11_B3',
                          'V_SOUREC',
                          'TE_11_B3_WF',
                          '3#热风炉拱顶温度1'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.V_SOUREC.TE_11_B3,
                          'TE_11_B3',
                          'V_SOUREC',
                          'TE_11_B3_WF'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.TE_11_B3)+" ℃ ")]),_c('div',{staticClass:"jiange3",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'DTXR_B3',
                          'V_RCL3',
                          'DTXR_B3_WF',
                          '3#动态蓄热能力'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.V_RCL3.DTXR_B3,
                          'DTXR_B3',
                          'V_RCL3',
                          'DTXR_B3_WF'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_RCL3.DTXR_B3)+" % ")])])]),_c('div',{staticClass:"gd4"},[_c('div',{staticClass:"htwd"},[_vm._v("4号炉")]),_c('div',{staticClass:"htwdtitle"},[_c('div',{staticClass:"jiange3",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'TE_11_B4',
                          'V_SOUREC',
                          'TE_11_B4_WF',
                          '4#热风炉拱顶温度1'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.V_SOUREC.TE_11_B4,
                          'TE_11_B4',
                          'V_SOUREC',
                          'TE_11_B4_WF'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.TE_11_B4)+"℃ ")]),_c('div',{staticClass:"jiange3",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'DTXR_B4',
                          'V_RCL4',
                          'DTXR_B4_WF',
                          '4#动态蓄热能力'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.V_RCL4.DTXR_B4,
                          'DTXR_B4',
                          'V_RCL4',
                          'DTXR_B4_WF'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_RCL4.DTXR_B4)+"% ")])])])]),_c('div',{staticClass:"flex",staticStyle:{"margin-left":"0vw","margin-top":"0.8vh"}},[_c('div',{staticClass:"famen1 jiange2",on:{"click":function($event){return _vm.toCompon(7, 1)}}},[_c('img',{attrs:{"src":_vm._f("typeFifter")(_vm.infoList.V_SOUREC.KB_21_B1),"alt":""}})]),_c('div',{staticClass:"famen1 jiange4",on:{"click":function($event){return _vm.toCompon(7, 2)}}},[_c('img',{attrs:{"src":_vm._f("typeFifter")(_vm.infoList.V_SOUREC.KB_21_B2),"alt":""}})]),_c('div',{staticClass:"famen1 jiange5",on:{"click":function($event){return _vm.toCompon(7, 3)}}},[_c('img',{attrs:{"src":_vm._f("typeFifter")(_vm.infoList.V_SOUREC.KB_21_B3),"alt":""}})]),_c('div',{staticClass:"famen1 jiange6",on:{"click":function($event){return _vm.toCompon(7, 4)}}},[_c('img',{attrs:{"src":_vm._f("typeFifter")(_vm.infoList.V_SOUREC.KB_21_B4),"alt":""}})])]),_c('div',{staticClass:"flex",staticStyle:{"margin-left":"1.8vw","margin-top":"0.5vh"}},[_c('div',{staticClass:"child-data flex"},[_c('div',{staticClass:"button",style:({
                      background:
                        _vm.infoList.V_RS__p__RSFB1__p__XK01 &&
                        !_vm.infoList.V_RS__p__RSFB1__p__XK01.TS
                          ? '#2AFC30'
                          : 'red',
                    }),on:{"click":function($event){_vm.infoList.V_RS__p__RSFB1__p__XK01
                        ? _vm.toDetail(
                            1,
                            'XKA01',
                            'V_RS__p__RSFB1__p__XK01',
                            '',
                            '1#煤气流量先控'
                          )
                        : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"button",staticStyle:{"margin-left":"0vw"},style:({
                      background:
                        _vm.infoList.V_RS__p__RSFB1__p__MAN1.RM == 1
                          ? '#2AFC30'
                          : 'red',
                    }),on:{"click":function($event){return _vm.toCompon(
                        0,
                        'AV',
                        'V_RS__p__RSFB1__p__MAN1',
                        'MANA1_AV_WF',
                        '1#炉煤气流量调节阀'
                      )}}},[_vm._v(" A ")]),_c('div',{staticClass:"lefttext",staticStyle:{"margin-left":"0.2vw"}},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'KB_11_B1',
                          'V_SOUREC',
                          'KB_11_B1_WF',
                          '1#热风炉煤气阀位调节1BCS'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.V_SOUREC.KB_11_B1,
                          'KB_11_B1',
                          'V_SOUREC',
                          'KB_11_B1_WF'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.KB_11_B1)+" % ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'FVI_11_B1',
                          'V_SOUREC',
                          'FVI_11_B1_WF',
                          '1#热风炉煤气阀位反馈1'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.V_SOUREC.FVI_11_B1,
                          'FVI_11_B1',
                          'V_SOUREC',
                          'FVI_11_B1_WF'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.FVI_11_B1)+" % ")])])]),_c('div',{staticClass:"child-data flex"},[_c('div',{staticClass:"button",style:({
                      background:
                        _vm.infoList.V_RS__p__RSFB2__p__XK01 &&
                        !_vm.infoList.V_RS__p__RSFB2__p__XK01.TS
                          ? '#2AFC30'
                          : 'red',
                    }),on:{"click":function($event){_vm.infoList.V_RS__p__RSFB2__p__XK01
                        ? _vm.toDetail(
                            1,
                            'XKB01',
                            'V_RS__p__RSFB2__p__XK01',
                            '',
                            '2#煤气流量先控'
                          )
                        : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"button",staticStyle:{"margin-left":"0.1vw"},style:({
                      background:
                        _vm.infoList.V_RS__p__RSFB2__p__MAN1.RM == 1
                          ? '#2AFC30'
                          : 'red',
                    }),on:{"click":function($event){return _vm.toCompon(
                        0,
                        'AV',
                        'V_RS__p__RSFB2__p__MAN1',
                        'MANA1_AV_WF',
                        '2#炉煤气流量调节阀'
                      )}}},[_vm._v(" A ")]),_c('div',{staticClass:"lefttext",staticStyle:{"margin-left":"0.2vw"}},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'KB_11_B2',
                          'V_SOUREC',
                          'KB_11_B2_WF',
                          '2#热风炉煤气阀位调节1BCS'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.V_SOUREC.KB_11_B2,
                          'KB_11_B2',
                          'V_SOUREC',
                          'KB_11_B2_WF'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.KB_11_B2)+" % ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'FVI_11_B2',
                          'V_SOUREC',
                          'FVI_11_B2_WF',
                          '2#热风炉煤气阀位反馈1'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.V_SOUREC.FVI_11_B2,
                          'FVI_11_B2',
                          'V_SOUREC',
                          'FVI_11_B2_WF'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.FVI_11_B2)+" % ")])])]),_c('div',{staticClass:"flex",staticStyle:{"margin-left":"-0.3vw"}},[_c('div',{staticClass:"button",style:({
                      background:
                        _vm.infoList.V_RS__p__RSFB3__p__XK01 &&
                        !_vm.infoList.V_RS__p__RSFB3__p__XK01.TS
                          ? '#2AFC30'
                          : 'red',
                    }),on:{"click":function($event){_vm.infoList.V_RS__p__RSFB3__p__XK01
                        ? _vm.toDetail(
                            1,
                            'XKC01',
                            'V_RS__p__RSFB3__p__XK01',
                            '',
                            '3#煤气流量先控'
                          )
                        : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"button",staticStyle:{"margin-left":"0.1vw"},style:({
                      background:
                        _vm.infoList.V_RS__p__RSFB3__p__MAN1.RM == 1
                          ? '#2AFC30'
                          : 'red',
                    }),on:{"click":function($event){return _vm.toCompon(
                        0,
                        'AV',
                        'V_RS__p__RSFB3__p__MAN1',
                        'MANA1_AV_WF',
                        '3#炉煤气流量调节阀'
                      )}}},[_vm._v(" A ")]),_c('div',{staticClass:"lefttext",staticStyle:{"margin-left":"0.2vw"}},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'KB_11_B3',
                          'V_SOUREC',
                          'KB_11_B3_WF',
                          '3#热风炉煤气阀位调节1BCS'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.V_SOUREC.KB_11_B3,
                          'KB_11_B3',
                          'V_SOUREC',
                          'KB_11_B3_WF'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.KB_11_B3)+" % ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'FVI_11_B3',
                          'V_SOUREC',
                          'FVI_11_B3_WF',
                          '3#热风炉煤气阀位反馈1'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.V_SOUREC.FVI_11_B3,
                          'FVI_11_B3',
                          'V_SOUREC',
                          'FVI_11_B3_WF'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.FVI_11_B3)+" % ")])])]),_c('div',{staticClass:"flex",staticStyle:{"margin-left":"-0.3vw"}},[_c('div',{staticClass:"button",style:({
                      background:
                        _vm.infoList.V_RS__p__RSFB4__p__XK01 &&
                        !_vm.infoList.V_RS__p__RSFB4__p__XK01.TS
                          ? '#2AFC30'
                          : 'red',
                    }),on:{"click":function($event){_vm.infoList.V_RS__p__RSFB4__p__XK01
                        ? _vm.toDetail(
                            1,
                            'XKD01',
                            'V_RS__p__RSFB4__p__XK01',
                            '',
                            '4#煤气流量先控'
                          )
                        : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"button",staticStyle:{"margin-left":"0.1vw"},style:({
                      background:
                        _vm.infoList.V_RS__p__RSFB4__p__MAN1.RM == 1
                          ? '#2AFC30'
                          : 'red',
                    }),on:{"click":function($event){return _vm.toCompon(
                        0,
                        'AV',
                        'V_RS__p__RSFB4__p__MAN1',
                        'MANA1_AV_WF',
                        '4#炉煤气流量调节阀'
                      )}}},[_vm._v(" A ")]),_c('div',{staticClass:"lefttext",staticStyle:{"margin-left":"0.2vw"}},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'KB_11_B4',
                          'V_SOUREC',
                          'KB_11_B4_WF',
                          '4#热风炉煤气阀位调节1BCS'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.V_SOUREC.KB_11_B4,
                          'KB_11_B4',
                          'V_SOUREC',
                          'KB_11_B4_WF'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.KB_11_B4)+" % ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'FVI_11_B4',
                          'V_SOUREC',
                          'FVI_11_B4_WF',
                          '4#热风炉煤气阀位反馈1'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.V_SOUREC.FVI_11_B4,
                          'FVI_11_B4',
                          'V_SOUREC',
                          'FVI_11_B4_WF'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.FVI_11_B4)+" % ")])])])])])])]),_c('div',{staticClass:"left_middle"},[_c('div',{staticClass:"lefttext1 flex"},[_c('div',[_c('div',{staticClass:"hjg",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'TE_03_B',
                    'V_SOUREC',
                    'TE_03_B_WF',
                    '助燃风总管预热后温度'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.V_SOUREC.TE_03_B,
                    'TE_03_B',
                    'V_SOUREC',
                    'TE_03_B_WF'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.TE_03_B)+" ℃ ")]),_c('div',{staticClass:"hjg",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'PIC_02_B',
                    'V_SOUREC',
                    'PIC_02_B_WF',
                    '助燃风总管压力'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.V_SOUREC.PIC_02_B,
                    'PIC_02_B',
                    'V_SOUREC',
                    'PIC_02_B_WF'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.PIC_02_B)+" KPa ")]),_c('div',{staticClass:"hjg",staticStyle:{"color":"#5ab5ce"},on:{"click":function($event){_vm.infoList.V_GONGGONG__p__ZGXK02
                    ? _vm.toCompon(
                        2,
                        'SP',
                        'V_GONGGONG__p__ZGXK02',
                        'ZGXK02_SP_WF',
                        '1#风机总管压力先控'
                      )
                    : ''},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.V_GONGGONG__p__ZGXK02.SP,
                    'SP',
                    'V_GONGGONG__p__ZGXK02',
                    'ZGXK02_SP_WF'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_GONGGONG__p__ZGXK02 ? _vm.infoList.V_GONGGONG__p__ZGXK02.SP : "")+" KPa ")])])]),_c('div',{staticClass:"left_button flex"},[_c('div',{staticClass:"xinx1 fl"},[_c('div',{staticClass:"flex"},[_c('div',[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"button",style:({
                        background:
                          _vm.infoList.V_RS__p__RSFB1__p__XK02 &&
                          !_vm.infoList.V_RS__p__RSFB1__p__XK02.TS
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){_vm.infoList.V_RS__p__RSFB1__p__XK02
                          ? _vm.toDetail(
                              1,
                              'XKA02',
                              'V_RS__p__RSFB1__p__XK02',
                              '',
                              '1#空气流量先控'
                            )
                          : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"button",staticStyle:{"margin-left":"0.1vw"},style:({
                        background:
                          _vm.infoList.V_RS__p__RSFB1__p__MAN2.RM == 1
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){return _vm.toCompon(
                          0,
                          'AV ',
                          'V_RS__p__RSFB1__p__MAN2',
                          'MANA2_AV_WF',
                          '1#炉空气流量调节阀'
                        )}}},[_vm._v(" A ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"button",staticStyle:{"margin-top":"0.3vh"},style:({
                        background:
                          _vm.infoList.V_RS__p__RSFB1__p__XK04 &&
                          !_vm.infoList.V_RS__p__RSFB1__p__XK04.TS
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){_vm.infoList.V_RS__p__RSFB1__p__XK04
                          ? _vm.toDetail(
                              1,
                              'XKA04',
                              'V_RS__p__RSFB1__p__XK04',
                              '',
                              '1#废气温度调节先控'
                            )
                          : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"button",staticStyle:{"margin-left":"0.1vw","margin-top":"0.3vh"},style:({
                        background:
                          _vm.infoList.V_RS__p__RSFB1__p__XK05 &&
                          !_vm.infoList.V_RS__p__RSFB1__p__XK05.TS
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){_vm.infoList.V_RS__p__RSFB1__p__XK05
                          ? _vm.toDetail(
                              1,
                              'XKA05',
                              'V_RS__p__RSFB1__p__XK05',
                              '',
                              '1#拱顶温度调节空燃比先控'
                            )
                          : ''}}},[_vm._v(" X ")])])]),_c('div',[_c('div',{staticClass:"lefttext",staticStyle:{"padding-left":"0.1vw"}},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'KB_21_B1',
                          'V_SOUREC',
                          'KB_21_B1_WF',
                          '1#热风炉助燃风阀调节1BCS'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.V_SOUREC.KB_21_B1,
                          'KB_21_B1',
                          'V_SOUREC',
                          'KB_21_B1_WF'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.KB_21_B1)+" % ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'FVI_21_B1',
                          'V_SOUREC',
                          'FVI_21_B1_WF',
                          '1#热风炉助燃风阀位反馈1'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.V_SOUREC.FVI_21_B1,
                          'FVI_21_B1',
                          'V_SOUREC',
                          'FVI_21_B1_WF'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.FVI_21_B1)+" % ")])])])])]),_c('div',{staticClass:"xinx2 flex"},[_c('div',{staticClass:"flex"},[_c('div',[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"button",style:({
                        background:
                          _vm.infoList.V_RS__p__RSFB2__p__XK02 &&
                          !_vm.infoList.V_RS__p__RSFB2__p__XK02.TS
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){_vm.infoList.V_RS__p__RSFB2__p__XK02
                          ? _vm.toDetail(
                              1,
                              'XKB02',
                              'V_RS__p__RSFB2__p__XK02',
                              '',
                              '2#空气流量先控'
                            )
                          : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"button",staticStyle:{"margin-left":"0.1vw"},style:({
                        background:
                          _vm.infoList.V_RS__p__RSFB2__p__MAN2 &&
                          _vm.infoList.V_RS__p__RSFB2__p__MAN2.RM == 1
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){_vm.infoList.V_RS__p__RSFB2__p__MAN2
                          ? _vm.toCompon(
                              0,
                              'AV',
                              'V_RS__p__RSFB2__p__MAN2',
                              'MANA2_AV_WF',
                              '2#炉空气流量调节阀'
                            )
                          : ''}}},[_vm._v(" A ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"button",staticStyle:{"margin-top":"0.3vh"},style:({
                        background:
                          _vm.infoList.V_RS__p__RSFB2__p__XK04 &&
                          !_vm.infoList.V_RS__p__RSFB2__p__XK04.TS
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){_vm.infoList.V_RS__p__RSFB2__p__XK04
                          ? _vm.toDetail(
                              1,
                              'XKB04',
                              'V_RS__p__RSFB2__p__XK04',
                              '',
                              '2#废气温度调节先控'
                            )
                          : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"button",staticStyle:{"margin-left":"0.1vw","margin-top":"0.3vh"},style:({
                        background:
                          _vm.infoList.V_RS__p__RSFB2__p__XK05 &&
                          _vm.infoList.V_RS__p__RSFB2__p__XK05.RM == 1
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){_vm.infoList.V_RS__p__RSFB2__p__XK05
                          ? _vm.toDetail(
                              1,
                              'XKB05',
                              'V_RS__p__RSFB2__p__XK05',
                              '',
                              '2#拱顶温度调节空燃比先控'
                            )
                          : ''}}},[_vm._v(" X ")])])])]),_c('div',[_c('div',{staticClass:"lefttext",staticStyle:{"padding-left":"0.1vw"}},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                        2,
                        'KB_21_B2',
                        'V_SOUREC',
                        'KB_21_B2_WF',
                        '2#热风炉助燃风阀调节1BCS'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.V_SOUREC.KB_21_B2,
                        'KB_21_B2',
                        'V_SOUREC',
                        'KB_21_B2_WF'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.KB_21_B2)+" % ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                        2,
                        'FVI_21_B2',
                        'V_SOUREC',
                        'FVI_21_B2_WF',
                        '2#热风炉助燃风阀位反馈1'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.V_SOUREC.FVI_21_B2,
                        'FVI_21_B2',
                        'V_SOUREC',
                        'FVI_21_B2_WF'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.FVI_21_B2)+" % ")])])])]),_c('div',{staticClass:"xinx3 flex"},[_c('div',{staticClass:"flex"},[_c('div',[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"button",style:({
                        background:
                          _vm.infoList.V_RS__p__RSFB3__p__XK02 &&
                          !_vm.infoList.V_RS__p__RSFB3__p__XK02.TS
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){_vm.infoList.V_RS__p__RSFB3__p__XK02
                          ? _vm.toDetail(
                              1,
                              'XKC02',
                              'V_RS__p__RSFB3__p__XK02',
                              '',
                              '3#空气流量先控'
                            )
                          : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"button",staticStyle:{"margin-left":"0.1vw"},style:({
                        background:
                          _vm.infoList.V_RS__p__RSFB3__p__MAN2 &&
                          _vm.infoList.V_RS__p__RSFB3__p__MAN2.RM == 1
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){_vm.infoList.V_RS__p__RSFB3__p__MAN2
                          ? _vm.toCompon(
                              0,
                              'AV',
                              'V_RS__p__RSFB3__p__MAN2',
                              'MANC2_AV_WF',
                              '3#炉空气流量调节阀'
                            )
                          : ''}}},[_vm._v(" A ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"button",staticStyle:{"margin-top":"0.3vh"},style:({
                        background:
                          _vm.infoList.V_RS__p__RSFB3__p__XK04 &&
                          !_vm.infoList.V_RS__p__RSFB3__p__XK04.TS
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){_vm.infoList.V_RS__p__RSFB3__p__XK04
                          ? _vm.toDetail(
                              1,
                              'XKC04',
                              'V_RS__p__RSFB3__p__XK04',
                              '',
                              '3#废气温度调节先控'
                            )
                          : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"button",staticStyle:{"margin-left":"0.1vw","margin-top":"0.3vh"},style:({
                        background:
                          _vm.infoList.V_RS__p__RSFB3__p__XK05 &&
                          _vm.infoList.V_RS__p__RSFB3__p__XK05.RM == 1
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){_vm.infoList.V_RS__p__RSFB3__p__XK05
                          ? _vm.toDetail(
                              1,
                              'XKC05',
                              'V_RS__p__RSFB3__p__XK05',
                              '',
                              '3#拱顶温度调节空燃比先控'
                            )
                          : ''}}},[_vm._v(" X ")])])])]),_c('div',[_c('div',{staticClass:"lefttext",staticStyle:{"padding-left":"0.1vw"}},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                        2,
                        'KB_21_B3',
                        'V_SOUREC',
                        'KB_21_B3_WF',
                        '3#热风炉助燃风阀调节1BCS'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.V_SOUREC.KB_21_B3,
                        'KB_21_B3',
                        'V_SOUREC',
                        'KB_21_B3_WF'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.KB_21_B3)+" % ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                        2,
                        'FVI_21_B3',
                        'V_SOUREC',
                        'FVI_21_B3_WF',
                        '3#热风炉助燃风阀位反馈1'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.V_SOUREC.FVI_21_B3,
                        'FVI_21_B3',
                        'V_SOUREC',
                        'FVI_21_B3_WF'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.FVI_21_B3)+" % ")])])])]),_c('div',{staticClass:"xinx4 flex"},[_c('div',{staticClass:"flex"},[_c('div',[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"button",style:({
                        background:
                          _vm.infoList.V_RS__p__RSFB4__p__XK02 &&
                          !_vm.infoList.V_RS__p__RSFB4__p__XK02.TS
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){_vm.infoList.V_RS__p__RSFB4__p__XK02
                          ? _vm.toDetail(
                              1,
                              'XKD02',
                              'V_RS__p__RSFB4__p__XK02',
                              '',
                              '4#空气流量先控'
                            )
                          : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"button",staticStyle:{"margin-left":"0.1vw"},style:({
                        background:
                          _vm.infoList.V_RS__p__RSFB4__p__MAN2 &&
                          _vm.infoList.V_RS__p__RSFB4__p__MAN2.RM == 1
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){_vm.infoList.V_RS__p__RSFB4__p__MAN2
                          ? _vm.toCompon(
                              0,
                              'AV',
                              'V_RS__p__RSFB4__p__MAN2',
                              'MAND2_AV_WF',
                              '4#炉空气流量调节阀'
                            )
                          : ''}}},[_vm._v(" A ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"button",staticStyle:{"margin-top":"0.3vh"},style:({
                        background:
                          _vm.infoList.V_RS__p__RSFB4__p__XK04 &&
                          !_vm.infoList.V_RS__p__RSFB4__p__XK04.TS
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){_vm.infoList.V_RS__p__RSFB4__p__XK04
                          ? _vm.toDetail(
                              1,
                              'XKD04',
                              'V_RS__p__RSFB4__p__XK04',
                              '',
                              '4#废气温度调节先控'
                            )
                          : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"button",staticStyle:{"margin-left":"0.1vw","margin-top":"0.3vh"},style:({
                        background:
                          _vm.infoList.V_RS__p__RSFB4__p__XK05 &&
                          _vm.infoList.V_RS__p__RSFB4__p__XK05.RM == 1
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){_vm.infoList.V_RS__p__RSFB4__p__XK05
                          ? _vm.toDetail(
                              1,
                              'XKD05',
                              'V_RS__p__RSFB4__p__XK05',
                              '',
                              '4#拱顶温度调节空燃比先控'
                            )
                          : ''}}},[_vm._v(" X ")])])]),_c('div',[_c('div',{staticClass:"lefttext fl",staticStyle:{"padding-left":"0.1vw"}},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'KB_21_B4',
                          'V_SOUREC',
                          'KB_21_B4_WF',
                          '4#热风炉助燃风阀调节1BCS'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.V_SOUREC.KB_21_B4,
                          'KB_21_B4',
                          'V_SOUREC',
                          'KB_21_B4_WF'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.KB_21_B4)+" % ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'FVI_21_B4',
                          'V_SOUREC',
                          'FVI_21_B4_WF',
                          '4#热风炉助燃风阀位反馈1'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.V_SOUREC.FVI_21_B4,
                          'FVI_21_B4',
                          'V_SOUREC',
                          'FVI_21_B4_WF'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.FVI_21_B4)+" % ")])])])])])]),_c('div',{staticClass:"flex",staticStyle:{"margin-left":"16.7vw","margin-top":"0.5vh"}},[_c('div',{staticClass:"famen1",on:{"click":function($event){return _vm.toCompon(7, 1)}}},[_c('img',{attrs:{"src":_vm._f("typeFifter")(_vm.infoList.V_SOUREC.KB_21_B1),"alt":""}})]),_c('div',{staticClass:"famen1 jiange4",on:{"click":function($event){return _vm.toCompon(7, 2)}}},[_c('img',{attrs:{"src":_vm._f("typeFifter")(_vm.infoList.V_SOUREC.KB_21_B2),"alt":""}})]),_c('div',{staticClass:"famen1 jiange5",on:{"click":function($event){return _vm.toCompon(7, 3)}}},[_c('img',{attrs:{"src":_vm._f("typeFifter")(_vm.infoList.V_SOUREC.KB_21_B3),"alt":""}})]),_c('div',{staticClass:"famen1 jiange6",on:{"click":function($event){return _vm.toCompon(7, 4)}}},[_c('img',{attrs:{"src":_vm._f("typeFifter")(_vm.infoList.V_SOUREC.KB_21_B4),"alt":""}})])])]),_c('div',{staticClass:"left_down flex"},[_c('div',{staticClass:"left_left"},[_c('div',{staticClass:"lable2"},[_vm._v("空气管道")]),_vm._m(1),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"xinx5 flex"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"button",style:({
                      background:
                        _vm.infoList.V_GONGGONG__p__ZGXK02 &&
                        !_vm.infoList.V_GONGGONG__p__ZGXK02.TS
                          ? '#2AFC30'
                          : 'red',
                    }),on:{"click":function($event){_vm.infoList.V_GONGGONG__p__ZGXK02
                        ? _vm.toDetail(
                            1,
                            'ZGXK02',
                            'V_GONGGONG__p__ZGXK02',
                            '',
                            '1#风机总管压力先控'
                          )
                        : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"button",staticStyle:{"margin-left":"0.1vw"},style:({
                      background:
                        _vm.infoList.V_GONGGONG__p__MAN2 &&
                        _vm.infoList.V_GONGGONG__p__MAN2.RM == 1
                          ? '#2AFC30'
                          : 'red',
                    }),on:{"click":function($event){_vm.infoList.V_GONGGONG__p__MAN2
                        ? _vm.toCompon(
                            0,
                            'AV',
                            'V_GONGGONG__p__MAN2',
                            'MANA2_AV_WF',
                            '1#助燃风总管压力调节阀'
                          )
                        : ''}}},[_vm._v(" A ")])]),_c('div',{staticClass:"lefttext",staticStyle:{"padding-left":"0.2vw","margin-top":"0.3vh"}},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                        2,
                        'KX_02_B',
                        'V_SOUREC',
                        'KX_02_B_WF',
                        '助燃风总管1阀调节DCS'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.V_SOUREC.KX_02_B,
                        'KX_02_B',
                        'V_SOUREC',
                        'KX_02_B_WF'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.KX_02_B)+" % ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                        2,
                        'FVI_02_B',
                        'V_SOUREC',
                        'FVI_02_B_WF',
                        '助燃风总管1阀位反馈'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.V_SOUREC.FVI_02_B,
                        'FVI_02_B',
                        'V_SOUREC',
                        'FVI_02_B_WF'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.FVI_02_B)+" % ")])])]),_c('div',{staticClass:"xinx6 flex"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"button",style:({
                      background:
                        _vm.infoList.V_GONGGONG__p__ZGXK03 &&
                        !_vm.infoList.V_GONGGONG__p__ZGXK03.TS
                          ? '#2AFC30'
                          : 'red',
                    }),on:{"click":function($event){_vm.infoList.V_GONGGONG__p__ZGXK02
                        ? _vm.toDetail(
                            1,
                            'ZGXK03',
                            'V_GONGGONG__p__ZGXK03',
                            '',
                            '2#风机总管压力先控'
                          )
                        : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"button",staticStyle:{"margin-left":"0.1vw"},style:({
                      background:
                        _vm.infoList.V_GONGGONG__p__MAN2 &&
                        _vm.infoList.V_GONGGONG__p__MAN2.RM == 1
                          ? '#2AFC30'
                          : 'red',
                    }),on:{"click":function($event){_vm.infoList.V_GONGGONG__p__MAN3
                        ? _vm.toCompon(
                            0,
                            'AV',
                            'V_GONGGONG__p__MAN3',
                            'MAN3_AV_WF',
                            '2#助燃风总管压力调节阀'
                          )
                        : ''}}},[_vm._v(" A ")])]),_c('div',{staticClass:"lefttext",staticStyle:{"padding-left":"0.2vw","margin-top":"0.5vh"}},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                        2,
                        'KX_03_B',
                        'V_SOUREC',
                        'KX_03_B_WF',
                        '助燃风总管2阀调节DCS'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.V_SOUREC.KX_03_B,
                        'KX_03_B',
                        'V_SOUREC',
                        'KX_03_B_WF'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.KX_03_B)+" % ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                        2,
                        'FVI_03_B',
                        'V_SOUREC',
                        'FVI_03_B_WF',
                        '助燃风总管2阀位反馈'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.V_SOUREC.FVI_03_B,
                        'FVI_03_B',
                        'V_SOUREC',
                        'FVI_03_B_WF'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.FVI_03_B)+" % ")])])])]),_c('div',{staticClass:"lable3"},[_vm._v("废气管道")])]),_c('div',{staticClass:"left_right"},[_c('div',{staticClass:"titlable"},[_vm._v("单位：min")]),_c('div',{staticClass:"biaoge1"},[_c('el-table',{staticStyle:{"width":"35vw","background-color":"transparent"},attrs:{"data":_vm.tableData,"border":"","header-cell-style":_vm.headerStyle,"row-style":{ height: '1.5vh', background: 'transparent' },"cell-style":{
                  padding: '0px',
                  borderColor: '#0f3747',
                }}},[_c('el-table-column',{attrs:{"prop":"number","label":"1号炉","height":"1vh","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.$index == 0)?_c('div',{staticClass:"wzcolor",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'SLSJ_B1',
                          'V_SOUREC',
                          'SLSJ_B1_WF',
                          '1#炉烧炉时间设定'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.V_SOUREC.SLSJ_B1,
                          'SLSJ_B1',
                          'V_SOUREC',
                          'SLSJ_B1_WF'
                        )}}},[_c('span',{staticStyle:{"display":"inline-block","padding-right":"1vw font-size:1.7vh"},style:({
                          color: _vm.infoList.V_STATE.SLKG1 ? '#F32028' : '#fff',
                        })},[_vm._v(" 烧炉")]),_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.SLSJ_B1)+" ")]):_vm._e(),(scope.$index == 1)?_c('div',{staticClass:"wzcolor"},[_c('span',{staticStyle:{"display":"inline-block","padding-left":"0px"},style:({
                          color: _vm.infoList.V_STATE.BLKG1 ? '#FDF061' : '#fff',
                        })},[_vm._v(" 焖炉")])]):_vm._e(),(scope.$index == 2)?_c('div',{staticClass:"wzcolor",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'SFSJ_B1',
                          'V_TJ1',
                          'SFSJ_B1_WF',
                          '1#本次送风时间'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.V_TJ1.SFSJ_B1,
                          'SFSJ_B1',
                          'V_TJ1',
                          'SFSJ_B1_WF'
                        )}}},[_c('span',{staticStyle:{"display":"inline-block","padding-right":"1vw"},style:({
                          color: _vm.infoList.V_STATE.SFKG1 ? 'green' : '#fff',
                        })},[_vm._v(" 送风")]),_vm._v(" "+_vm._s(_vm.infoList.V_TJ1.SFSJ_B1)+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"number1","label":"2号炉","align":"center","width":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.$index == 0)?_c('div',{staticClass:"wzcolor",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'SLSJ_B2',
                          'V_SOUREC',
                          'SLSJ_B2_WF',
                          '2#炉烧炉时间设定'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.V_SOUREC.SLSJ_B2,
                          'SLSJ_B2',
                          'V_SOUREC',
                          'SLSJ_B2_WF'
                        )}}},[_c('span',{staticStyle:{"color":"#f32028","display":"inline-block","padding-right":"15px"},style:({
                          color: _vm.infoList.V_STATE.SLKG2 ? '#F32028' : '#fff',
                        })},[_vm._v("烧炉")]),_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.SLSJ_B2)+" ")]):_vm._e(),(scope.$index == 1)?_c('div',{staticClass:"wzcolor"},[_c('span',{staticStyle:{"display":"inline-block","padding-right":"15px"},style:({
                          color: _vm.infoList.V_STATE.BLKG2 ? '#FDF061' : '#fff',
                        })},[_vm._v("焖炉")])]):_vm._e(),(scope.$index == 2)?_c('div',{staticClass:"wzcolor",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'SFSJ_B2',
                          'V_TJ2',
                          'SFSJ_B2_WF',
                          '2#本次送风时间'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.V_TJ2.SFSJ_B2,
                          'SFSJ_B2',
                          'V_TJ2',
                          'SFSJ_B2_WF'
                        )}}},[_c('span',{staticStyle:{"display":"inline-block","padding-right":"15px"},style:({
                          color: _vm.infoList.V_STATE.SFKG2 ? 'green' : '#fff',
                        })},[_vm._v("送风")]),_vm._v(" "+_vm._s(_vm.infoList.V_TJ2.SFSJ_B2)+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"number2","align":"center","label":"3号炉","width":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.$index == 0)?_c('div',{staticClass:"wzcolor",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'SLSJ_B3',
                          'V_SOUREC',
                          'SLSJ_B3_WF',
                          '3#炉烧炉时间设定'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.V_SOUREC.SLSJ_B3,
                          'SLSJ_B3',
                          'V_SOUREC',
                          'SLSJ_B3_WF'
                        )}}},[_c('span',{staticStyle:{"display":"inline-block","padding-right":"15px"},style:({
                          color: _vm.infoList.V_STATE.SLKG3 ? '#F32028' : '#fff',
                        })},[_vm._v("烧炉")]),_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.SLSJ_B3)+" ")]):_vm._e(),(scope.$index == 1)?_c('div',{staticClass:"wzcolor"},[_c('span',{staticStyle:{"display":"inline-block","padding-right":"15px"},style:({
                          color: _vm.infoList.V_STATE.BLKG3 ? '#FDF061' : '#fff',
                        })},[_vm._v("焖炉")])]):_vm._e(),(scope.$index == 2)?_c('div',{staticClass:"wzcolor",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'SFSJ_B3',
                          'V_TJ3',
                          'SFSJ_B3_WF',
                          '3#本次送风时间'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.V_TJ3.SFSJ_B3,
                          'SFSJ_B3',
                          'V_TJ3',
                          'SFSJ_B3_WF'
                        )}}},[_c('span',{staticStyle:{"display":"inline-block","padding-right":"15px"},style:({
                          color: _vm.infoList.V_STATE.SFKG3 ? 'green' : '#fff',
                        })},[_vm._v("送风")]),_vm._v(" "+_vm._s(_vm.infoList.V_TJ3.SFSJ_B3)+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"number3","align":"center","label":"4号炉","width":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.$index == 0)?_c('div',{staticClass:"wzcolor",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'SLSJ_B4',
                          'V_SOUREC',
                          'SLSJ_B4_WF',
                          '4#炉烧炉时间设定'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.V_SOUREC.SLSJ_B4,
                          'SLSJ_B4',
                          'V_SOUREC',
                          'SLSJ_B4_WF'
                        )}}},[_c('span',{staticStyle:{"display":"inline-block","padding-right":"15px"},style:({
                          color: _vm.infoList.V_STATE.SLKG4 ? '#F32028' : '#fff',
                        })},[_vm._v("烧炉")]),_vm._v(_vm._s(_vm.infoList.V_SOUREC.SLSJ_B4)+" ")]):_vm._e(),(scope.$index == 1)?_c('div',{staticClass:"wzcolor"},[_c('span',{staticStyle:{"display":"inline-block","padding-right":"15px"},style:({
                          color: _vm.infoList.V_STATE.BLKG4 ? '#FDF061' : '#fff',
                        })},[_vm._v("焖炉")])]):_vm._e(),(scope.$index == 2)?_c('div',{staticClass:"wzcolor",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'SFSJ_B4',
                          'V_TJ4',
                          'SFSJ_B4_WF',
                          '4#本次送风时间'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.V_TJ4.SFSJ_B4,
                          'SFSJ_B4',
                          'V_TJ4',
                          'SFSJ_B4_WF'
                        )}}},[_c('span',{staticStyle:{"display":"inline-block","padding-right":"15px"},style:({
                          color: _vm.infoList.V_STATE.SFKG4 ? 'green' : '#fff',
                        })},[_vm._v("送风")]),_vm._v(" "+_vm._s(_vm.infoList.V_TJ4.SFSJ_B4)+" ")]):_vm._e()]}}])})],1)],1),_c('div',{staticClass:"biaoge2"},[_c('el-table',{staticStyle:{"width":"35vw","height":"22vh","margin-top":"1vh","background-color":"transparent"},attrs:{"data":_vm.tableData2,"border":"","header-cell-style":_vm.headerStyle,"row-style":{ height: '1.8vh', background: 'transparent' },"cell-style":{ padding: '1px', borderColor: '#0f3747' }}},[_c('el-table-column',{attrs:{"prop":"number","label":"","align":"left","width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"wzcolor1",staticStyle:{"color":"#5ca4a6"}},[_vm._v(_vm._s(scope.row.number))])]}}])}),_c('el-table-column',{attrs:{"prop":"number1","label":"1号炉","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"wzcolor1",style:(scope.$index == 0
                          ? 'color:#00b4ff;cursor: pointer;'
                          : ''),on:{"click":function($event){scope.$index == 0
                          ? _vm.toIpt(
                              _vm.infoList.V_SP.SP11,
                              '1号炉烧炉时间',
                              'SP11',
                              'V_SP'
                            )
                          : scope.$index == 2
                          ? _vm.toCompon(
                              2,
                              'TE_13_B1',
                              'V_SOUREC',
                              'TE_13_B1_WF',
                              '1#热风炉废气温度'
                            )
                          : scope.$index == 3
                          ? _vm.toCompon(
                              2,
                              'FQXL_B1',
                              'V_TJ1',
                              'FQXL_B1_WF',
                              '1#废气斜率'
                            )
                          : scope.$index == 4
                          ? _vm.toCompon(
                              2,
                              'YCFQWD_B1',
                              'V_RS1',
                              'YCFQWD_B1_WF',
                              '1#炉预测废气温度'
                            )
                          : ''},"dblclick":function($event){scope.$index == 2
                          ? _vm.Cclick(
                              _vm.infoList.V_SOUREC.TE_13_B1,
                              'TE_13_B1',
                              'V_SOUREC',
                              'TE_13_B1_WF'
                            )
                          : scope.$index == 3
                          ? _vm.Cclick(
                              _vm.infoList.V_TJ1.FQXL_B1,
                              'FQXL_B1',
                              'V_TJ1',
                              'FQXL_B1_WF'
                            )
                          : scope.$index == 4
                          ? _vm.Cclick(
                              _vm.infoList.V_RS1.YCFQWD_B1,
                              'YCFQWD_B1',
                              'V_RS1',
                              'YCFQWD_B1_WF'
                            )
                          : ''}}},[_vm._v(" "+_vm._s(scope.$index == 0 ? _vm.infoList.V_SP.SP11 : scope.$index == 1 && _vm.infoList.V_RS1.SLSJ_S_B1 >= 0 ? _vm.infoList.V_RS1.SLSJ_S_B1 : scope.$index == 2 ? _vm.infoList.V_SOUREC.TE_13_B1 : scope.$index == 3 ? _vm.infoList.V_TJ1.FQXL_B1 : scope.$index == 4 ? _vm.infoList.V_RS1.YCFQWD_B1 : "0")+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"number2","label":"2号炉","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"wzcolor1",style:(scope.$index == 0
                          ? 'color:#00b4ff;cursor: pointer;'
                          : ''),on:{"click":function($event){scope.$index == 0
                          ? _vm.toIpt(
                              _vm.infoList.V_SP.SP21,
                              '2号炉烧炉时间',
                              'SP21',
                              'V_SP'
                            )
                          : scope.$index == 2
                          ? _vm.toCompon(
                              2,
                              'TE_13_B2',
                              'V_SOUREC',
                              'TE_13_B2_WF',
                              '2#热风炉废气温度'
                            )
                          : scope.$index == 3
                          ? _vm.toCompon(
                              2,
                              'FQXL_B2',
                              'V_TJ2',
                              'FQXL_B2_WF',
                              '2#废气斜率'
                            )
                          : scope.$index == 4
                          ? _vm.toCompon(
                              2,
                              'YCFQWD_B2',
                              'V_RS2',
                              'YCFQWD_B2_WF',
                              '2#炉预测废气温度'
                            )
                          : ''},"dblclick":function($event){scope.$index == 2
                          ? _vm.Cclick(
                              _vm.infoList.V_SOUREC.TE_13_B2,
                              'TE_13_B2',
                              'V_SOUREC',
                              'TE_13_B2_WF'
                            )
                          : scope.$index == 3
                          ? _vm.Cclick(
                              _vm.infoList.V_TJ2.FQXL_B2,
                              'FQXL_B2',
                              'V_TJ2',
                              'FQXL_B2_WF'
                            )
                          : scope.$index == 4
                          ? _vm.Cclick(
                              _vm.infoList.V_RS2.YCFQWD_B2,
                              'YCFQWD_B2',
                              'V_RS2',
                              'YCFQWD_B2_WF'
                            )
                          : ''}}},[_vm._v(" "+_vm._s(scope.$index == 0 ? _vm.infoList.V_SP.SP21 : scope.$index == 1 && _vm.infoList.V_RS2.SLSJ_S_B2 >= 0 ? _vm.infoList.V_RS2.SLSJ_S_B2 : scope.$index == 2 ? _vm.infoList.V_SOUREC.TE_13_B2 : scope.$index == 3 ? _vm.infoList.V_TJ2.FQXL_B2 : scope.$index == 4 ? _vm.infoList.V_RS2.YCFQWD_B2 : "0")+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"number3","label":"3号炉","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"wzcolor1",style:(scope.$index == 0
                          ? 'color:#00b4ff;cursor: pointer;'
                          : ''),on:{"click":function($event){scope.$index == 0
                          ? _vm.toIpt(
                              _vm.infoList.V_SP.SP31,
                              '3号炉烧炉时间',
                              'SP31',
                              'V_SP'
                            )
                          : scope.$index == 2
                          ? _vm.toCompon(
                              2,
                              'TE_13_B3',
                              'V_SOUREC',
                              'TE_13_B3_WF',
                              '3#热风炉废气温度'
                            )
                          : scope.$index == 3
                          ? _vm.toCompon(
                              2,
                              'FQXL_B3',
                              'V_TJ3',
                              'FQXL_B3_WF',
                              '3#废气斜率'
                            )
                          : scope.$index == 4
                          ? _vm.toCompon(
                              2,
                              'YCFQWD_B3',
                              'V_RS3',
                              'YCFQWD_B3_WF',
                              '3#炉预测废气温度'
                            )
                          : ''},"dblclick":function($event){scope.$index == 2
                          ? _vm.Cclick(
                              _vm.infoList.V_SOUREC.TE_13_B3,
                              'TE_13_B3',
                              'V_SOUREC',
                              'TE_13_B3_WF',
                              '3#热风炉废气温度'
                            )
                          : scope.$index == 3
                          ? _vm.Cclick(
                              _vm.infoList.V_TJ3.FQXL_B3,
                              'FQXL_B3',
                              'V_TJ3',
                              'FQXL_B3_WF',
                              '3#废气斜率'
                            )
                          : scope.$index == 4
                          ? _vm.Cclick(
                              _vm.infoList.V_RS3.YCFQWD_B3,
                              'YCFQWD_B3',
                              'V_RS3',
                              'YCFQWD_B3_WF',
                              '3#炉预测废气温度'
                            )
                          : ''}}},[_vm._v(" "+_vm._s(scope.$index == 0 ? _vm.infoList.V_SP.SP31 : scope.$index == 1 && _vm.infoList.V_RS3.SLSJ_S_B3 >= 0 ? _vm.infoList.V_RS3.SLSJ_S_B3 : scope.$index == 2 ? _vm.infoList.V_SOUREC.TE_13_B3 : scope.$index == 3 ? _vm.infoList.V_TJ3.FQXL_B3 : scope.$index == 4 ? _vm.infoList.V_RS3.YCFQWD_B3 : "0")+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"number4","label":"4号炉","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"wzcolor1",style:(scope.$index == 0
                          ? 'color:#00b4ff;cursor: pointer;'
                          : ''),on:{"click":function($event){scope.$index == 0
                          ? _vm.toIpt(
                              _vm.infoList.V_SP.SP41,
                              '4号炉烧炉时间',
                              'SP41',
                              'V_SP'
                            )
                          : scope.$index == 2
                          ? _vm.toCompon(
                              2,
                              'TE_13_B4',
                              'V_SOUREC',
                              'TE_13_B4_WF',
                              '4#热风炉废气温度'
                            )
                          : scope.$index == 3
                          ? _vm.toCompon(
                              2,
                              'FQXL_B4',
                              'V_TJ4',
                              'FQXL_B4_WF',
                              '4#废气斜率'
                            )
                          : scope.$index == 4
                          ? _vm.toCompon(
                              2,
                              'YCFQWD_B4',
                              'V_RS4',
                              'YCFQWD_B4_WF',
                              '4#炉预测废气温度'
                            )
                          : ''},"dblclick":function($event){scope.$index == 2
                          ? _vm.Cclick(
                              _vm.infoList.V_SOUREC.TE_13_B4,
                              'TE_13_B4',
                              'V_SOUREC',
                              'TE_13_B4_WF'
                            )
                          : scope.$index == 3
                          ? _vm.Cclick(
                              _vm.infoList.V_TJ4.FQXL_B4,
                              'FQXL_B4',
                              'V_TJ4',
                              'FQXL_B4_WF'
                            )
                          : scope.$index == 4
                          ? _vm.Cclick(
                              _vm.infoList.V_RS4.YCFQWD_B4,
                              'YCFQWD_B4',
                              'V_RS4',
                              'YCFQWD_B4_WF'
                            )
                          : ''}}},[_vm._v(" "+_vm._s(scope.$index == 0 ? _vm.infoList.V_SP.SP41 : scope.$index == 1 && _vm.infoList.V_RS4.SLSJ_S_B4 >= 0 ? _vm.infoList.V_RS4.SLSJ_S_B4 : scope.$index == 2 ? _vm.infoList.V_SOUREC.TE_13_B4 : scope.$index == 3 ? _vm.infoList.V_TJ4.FQXL_B4 : scope.$index == 4 ? _vm.infoList.V_RS4.YCFQWD_B4 : "0")+" ")])]}}])})],1)],1)])])]),_c('div',{staticClass:"right_all"},[_c('div',{staticClass:"canshu",on:{"click":function($event){return _vm.toCompon(5)}}},[_vm._v("公共参数")]),_c('div',{staticClass:"flex"},[_vm._m(2),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"lable4",on:{"click":function($event){return _vm.toCompon(2, 'TE_00_B', 'V_SOUREC', 'TE_00_B_WF', '热风温度1')},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.V_SOUREC.TE_00_B,
                  'TE_00_B',
                  'V_SOUREC',
                  'TE_00_B_WF'
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.TE_00_B)+"℃ ")]),_c('div',{staticClass:"lable4",staticStyle:{"margin-left":"13vw"}},[_vm._v("热风管道")])])]),_c('div',{staticClass:"biaoge3"},[_c('el-table',{staticStyle:{"width":"25vw","height":"16vh","background-color":"transparent"},attrs:{"data":_vm.tableData1,"border":"","header-cell-style":_vm.headerStyle,"row-style":{ height: '1.5vh', background: 'transparent' },"cell-style":{ padding: '0px', borderColor: '#0f3747' }}},[_c('el-table-column',{attrs:{"prop":"index","label":"炉号","align":"center","width":"70px","font-size":"1.7vh"}}),_c('el-table-column',{attrs:{"prop":"number","label":"基本空燃比","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"wzcolor",staticStyle:{"color":"#1eb0f5","cursor":"pointer","font-size":"1.7vh"},on:{"click":function($event){_vm.toIpt(
                      scope.$index == 0
                        ? _vm.infoList.V_SOUREC.JBFMB_B1
                        : scope.$index == 1
                        ? _vm.infoList.V_SOUREC.JBFMB_B2
                        : scope.$index == 2
                        ? _vm.infoList.V_SOUREC.JBFMB_B3
                        : scope.$index == 3
                        ? _vm.infoList.V_SOUREC.JBFMB_B4
                        : '',
                      scope.$index + 1 + '号基本空燃比',
                      'JBFMB_B' + (scope.$index + 1),
                      'V_SOUREC'
                    )}}},[_vm._v(" "+_vm._s(scope.$index == 0 ? _vm.infoList.V_SOUREC.JBFMB_B1 : scope.$index == 1 ? _vm.infoList.V_SOUREC.JBFMB_B2 : scope.$index == 2 ? _vm.infoList.V_SOUREC.JBFMB_B3 : scope.$index == 3 ? _vm.infoList.V_SOUREC.JBFMB_B4 : "")+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"number1","label":"优化空燃比","align":"center","width":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"wzcolor",on:{"click":function($event){_vm.toCompon(
                      2,
                      'YHFMB_B' + (scope.$index + 1),
                      'V_BCS' + (scope.$index + 1),
                      'YHFMB_B' + (scope.$index + 1) + '_WF'
                    )},"dblclick":function($event){_vm.Cclick(
                      _vm.infoList['V_BCS' + (scope.$index + 1)][
                        'YHFMB_B' + (scope.$index + 1)
                      ],
                      'YHFMB_B' + (scope.$index + 1),
                      'V_BCS' + (scope.$index + 1),
                      'YHFMB_B' + (scope.$index + 1) + '_WF'
                    )}}},[_vm._v(" "+_vm._s(scope.$index == 0 ? _vm.infoList.V_BCS1.YHFMB_B1 : scope.$index == 1 ? _vm.infoList.V_BCS2.YHFMB_B2 : scope.$index == 2 ? _vm.infoList.V_BCS3.YHFMB_B3 : scope.$index == 3 ? _vm.infoList.V_BCS4.YHFMB_B4 : "")+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"number2","align":"center","label":"实际空燃比"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"wzcolor",on:{"click":function($event){_vm.toCompon(
                      2,
                      'SJFMB_B' + (scope.$index + 1),
                      'V_BCS' + (scope.$index + 1),
                      'SJFMB_B' + (scope.$index + 1) + '_WF'
                    )},"dblclick":function($event){_vm.Cclick(
                      _vm.infoList['V_BCS' + (scope.$index + 1)][
                        'SJFMB_B' + (scope.$index + 1)
                      ],
                      'SJFMB_B' + (scope.$index + 1),
                      'V_BCS' + (scope.$index + 1),
                      'SJFMB_B' + (scope.$index + 1) + '_WF'
                    )}}},[_vm._v(" "+_vm._s(scope.$index == 0 ? _vm.infoList.V_BCS1.SJFMB_B1 : scope.$index == 1 ? _vm.infoList.V_BCS2.SJFMB_B2 : scope.$index == 2 ? _vm.infoList.V_BCS3.SJFMB_B3 : scope.$index == 3 ? _vm.infoList.V_BCS4.SJFMB_B4 : "")+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"number3","align":"center","label":"煤气干预量","width":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"wzcolor",staticStyle:{"color":"#1eb0f5","cursor":"pointer"},on:{"click":function($event){_vm.toIpt(
                      scope.$index == 0
                        ? _vm.infoList.V_SOUREC.GYMQL_B1
                        : scope.$index == 1
                        ? _vm.infoList.V_SOUREC.GYMQL_B2
                        : scope.$index == 2
                        ? _vm.infoList.V_SOUREC.GYMQL_B3
                        : scope.$index == 3
                        ? _vm.infoList.V_SOUREC.GYMQL_B4
                        : '',
                      scope.$index + 1 + '号煤气干预量',
                      'GYMQL_B' + (scope.$index + 1),
                      'V_SOUREC'
                    )}}},[_vm._v(" "+_vm._s(scope.$index == 0 ? _vm.infoList.V_SOUREC.GYMQL_B1 : scope.$index == 1 ? _vm.infoList.V_SOUREC.GYMQL_B2 : scope.$index == 2 ? _vm.infoList.V_SOUREC.GYMQL_B3 : scope.$index == 3 ? _vm.infoList.V_SOUREC.GYMQL_B4 : "")+" ")])]}}])})],1),_c('el-table',{staticStyle:{"width":"28vw","height":"20vh","background-color":"transparent","margin-top":"3vh"},attrs:{"data":_vm.tableData1,"border":"","header-cell-style":_vm.headerStyle,"row-style":{ height: '1.6vh', background: 'transparent' },"cell-style":{ padding: '0px', borderColor: '#0f3747' }}},[_c('el-table-column',{attrs:{"prop":"index","label":"炉号","align":"center","width":"50px"}}),_c('el-table-column',{staticStyle:{"padding-bottom":"1vh"},attrs:{"label":"煤气控制","align":"center"}},[_c('el-table-column',{attrs:{"prop":"number1","label":"目标值","align":"center","width":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"wzcolor",on:{"click":function($event){_vm.toCompon(
                        2,
                        'MQSP_B' + (scope.$index + 1),
                        'V_RS' + (scope.$index + 1),
                        'MQSP_B' + (scope.$index + 1) + '_WF'
                      )},"dblclick":function($event){_vm.Cclick(
                        _vm.infoList['V_RS' + (scope.$index + 1)][
                          'MQSP_B' + (scope.$index + 1)
                        ],
                        'MQSP_B' + (scope.$index + 1),
                        'V_RS' + (scope.$index + 1),
                        'MQSP_B' + (scope.$index + 1) + '_WF'
                      )}}},[_vm._v(" "+_vm._s(scope.$index == 0 ? _vm.infoList.V_RS1.MQSP_B1 : scope.$index == 1 ? _vm.infoList.V_RS2.MQSP_B2 : scope.$index == 2 ? _vm.infoList.V_RS3.MQSP_B3 : scope.$index == 3 ? _vm.infoList.V_RS4.MQSP_B4 : "")+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"number2","label":"测量值","align":"center","width":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"wzcolor",on:{"click":function($event){_vm.toCompon(
                        2,
                        'PV',
                        'V_RS__p__RSFB' + (scope.$index + 1) + '__p__XK01',
                        'XK' +
                          (scope.$index == 0
                            ? 'A'
                            : scope.$index == 1
                            ? 'B'
                            : scope.$index == 2
                            ? 'C'
                            : scope.$index == 3
                            ? 'D'
                            : '') +
                          '01_PV_WF'
                      )},"dblclick":function($event){_vm.Cclick(
                        _vm.infoList[
                          'V_RS__p__RSFB' + (scope.$index + 1) + '__p__XK01'
                        ].PV,
                        'PV',
                        'V_RS__p__RSFB' + (scope.$index + 1) + '__p__XK01',
                        'XK' +
                          (scope.$index == 0
                            ? 'A'
                            : scope.$index == 1
                            ? 'B'
                            : scope.$index == 2
                            ? 'C'
                            : scope.$index == 3
                            ? 'D'
                            : '') +
                          '01_PV_WF'
                      )}}},[_vm._v(" "+_vm._s(scope.$index == 0 ? _vm.infoList.V_RS__p__RSFB1__p__XK01.PV : scope.$index == 1 ? _vm.infoList.V_RS__p__RSFB2__p__XK01.PV : scope.$index == 2 ? _vm.infoList.V_RS__p__RSFB3__p__XK01.PV : scope.$index == 3 ? _vm.infoList.V_RS__p__RSFB4__p__XK01.PV : "")+" ")])]}}])})],1),_c('el-table-column',{attrs:{"label":"空气控制","align":"center"}},[_c('el-table-column',{attrs:{"prop":"number3","label":"目标值","align":"center","width":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"wzcolor",on:{"click":function($event){_vm.toCompon(
                        2,
                        'KQSP_B' + (scope.$index + 1),
                        'V_RS' + (scope.$index + 1),
                        'KQSP_B' + (scope.$index + 1) + '_WF'
                      )},"dblclick":function($event){_vm.Cclick(
                        _vm.infoList['V_RS' + (scope.$index + 1)][
                          'KQSP_B' + (scope.$index + 1)
                        ],
                        'KQSP_B' + (scope.$index + 1),
                        'V_RS' + (scope.$index + 1),
                        'KQSP_B' + (scope.$index + 1) + '_WF'
                      )}}},[_vm._v(" "+_vm._s(scope.$index == 0 ? _vm.infoList.V_RS1.KQSP_B1 : scope.$index == 1 ? _vm.infoList.V_RS2.KQSP_B2 : scope.$index == 2 ? _vm.infoList.V_RS3.KQSP_B3 : scope.$index == 3 ? _vm.infoList.V_RS4.KQSP_B4 : "")+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"number1","align":"center","label":"测量值","width":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"wzcolor",on:{"click":function($event){_vm.toCompon(
                        2,
                        'PV',
                        'V_RS__p__RSFB' + (scope.$index + 1) + '__p__XK02',
                        'XK' +
                          (scope.$index == 0
                            ? 'A'
                            : scope.$index == 1
                            ? 'B'
                            : scope.$index == 2
                            ? 'C'
                            : scope.$index == 3
                            ? 'D'
                            : '') +
                          '02_PV_WF'
                      )},"dblclick":function($event){_vm.Cclick(
                        _vm.infoList[
                          'V_RS__p__RSFB' + (scope.$index + 1) + '__p__XK02'
                        ].PV,
                        'PV',
                        'V_RS__p__RSFB' + (scope.$index + 1) + '__p__XK02',
                        'XK' +
                          (scope.$index == 0
                            ? 'A'
                            : scope.$index == 1
                            ? 'B'
                            : scope.$index == 2
                            ? 'C'
                            : scope.$index == 3
                            ? 'D'
                            : '') +
                          '02_PV_WF'
                      )}}},[_vm._v(" "+_vm._s(scope.$index == 0 ? _vm.infoList.V_RS__p__RSFB1__p__XK02.PV : scope.$index == 1 ? _vm.infoList.V_RS__p__RSFB2__p__XK02.PV : scope.$index == 2 ? _vm.infoList.V_RS__p__RSFB3__p__XK02.PV : scope.$index == 3 ? _vm.infoList.V_RS__p__RSFB4__p__XK02.PV : "")+" ")])]}}])})],1)],1)],1),_c('div',{staticClass:"biaoge4"},[_c('div',{staticClass:"flex",staticStyle:{"width":"28vw"}},[_c('div',{staticClass:"row1"},[_vm._v("煤气累积（w m³ )")]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row2-1"},[_vm._v("今天")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                    2,
                    'MQLJ5',
                    'V_TJ',
                    'MQLJ5_WF',
                    '热风炉支管今日煤气总用量'
                  )},"dblclick":function($event){return _vm.Cclick(_vm.infoList.V_TJ.MQLJ5, 'MQLJ5', 'V_TJ', 'MQLJ5_WF')}}},[_vm._v(" "+_vm._s(_vm.infoList.V_TJ.MQLJ5)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row2-1"},[_vm._v("昨天")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                    2,
                    'MQLJ10',
                    'V_TJ',
                    'MQLJ10_WF',
                    '热风炉支管昨日煤气总用量'
                  )},"dblclick":function($event){return _vm.Cclick(_vm.infoList.V_TJ.MQLJ10, 'MQLJ10', 'V_TJ', 'MQLJ10_WF')}}},[_vm._v(" "+_vm._s(_vm.infoList.V_TJ.MQLJ10)+" ")])])]),_c('div',{staticClass:"flex",staticStyle:{"width":"28vw"}},[_c('div',{staticClass:"row1"},[_vm._v("风温统计")]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row2-1"},[_vm._v("今天")]),_c('div',{on:{"click":function($event){return _vm.toCompon(2, 'JRFB', 'V_TJ', 'JRFB_WF', '今天热风温度均值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.V_TJ.JRFB, 'JRFB', 'V_TJ', 'JRFB_WF')}}},[_vm._v(" "+_vm._s(_vm.infoList.V_TJ.JRFB)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row2-1"},[_vm._v("昨天")]),_c('div',{on:{"click":function($event){return _vm.toCompon(2, 'JRFZ', 'V_TJ', 'JRFZ_WF', '昨天热风温度均值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.V_TJ.JRFZ, 'JRFZ', 'V_TJ', 'JRFZ_WF')}}},[_vm._v(" "+_vm._s(_vm.infoList.V_TJ.JRFZ)+" ")])])]),_c('div',{staticClass:"flex",staticStyle:{"width":"28vw"}},[_c('div',{staticClass:"row1"},[_vm._v("单位冷风消耗煤气量")]),_c('div',{staticClass:"row3",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(2, 'SFLJ_KHJ', 'V_TJ', 'SFLJ_KHJ_WF')},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.V_TJ.SFLJ_KHJ,
                  'SFLJ_KHJ',
                  'V_TJ',
                  'SFLJ_KHJ_WF'
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_TJ.SFLJ_KHJ)+" ")])])]),_c('div',{staticClass:"righlast flex"},[_c('div',{staticClass:"bottom",on:{"click":function($event){return _vm.toCompon(2, 'TE_07_B', 'V_SOUREC', 'TE_07_B_WF', '送风冷风温度')},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.V_SOUREC.TE_07_B,
                'TE_07_B',
                'V_SOUREC',
                'TE_07_B_WF'
              )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.TE_07_B)+" ℃ ")]),_c('div',{staticClass:"bottom",staticStyle:{"margin-left":"1vw"},on:{"click":function($event){return _vm.toCompon(
                2,
                'PIC_05_B',
                'V_SOUREC',
                'PIC_05_B_WF',
                '送风冷风压力'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.V_SOUREC.PIC_05_B,
                'PIC_05_B',
                'V_SOUREC',
                'PIC_05_B_WF'
              )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.PIC_05_B)+" Kpa ")]),_c('div',{staticClass:"bottom",staticStyle:{"margin-left":"1vw"},on:{"click":function($event){return _vm.toCompon(2, 'FT_02_B', 'V_SOUREC', 'FT_02_B_WF', '冷风总管流量')},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.V_SOUREC.FT_02_B,
                'FT_02_B',
                'V_SOUREC',
                'FT_02_B_WF'
              )}}},[_vm._v(" "+_vm._s(_vm.infoList.V_SOUREC.FT_02_B)+" m³/h ")]),_c('div',{staticClass:"lable5"},[_vm._v("冷气管道")])]),_c('div',{staticClass:"righlast1 flex"},[_c('div',{staticStyle:{"width":"5vw"}},[_vm._v("富氧流量")]),_c('div',[_c('span',{on:{"click":function($event){return _vm.toCompon(2, 'FT_03_B', 'V_SOUREC', 'FT_03_B_WF', '富氧流量')},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.V_SOUREC.FT_03_B,
                  'FT_03_B',
                  'V_SOUREC',
                  'FT_03_B_WF'
                )}}},[_vm._v(_vm._s(_vm.infoList.V_SOUREC.FT_03_B)+" m³/h")])])])])])]),(_vm.isMshow)?_c('Manual',{key:_vm.isIndex,attrs:{"historyname":_vm.Manualname,"node":_vm.Manualnode,"Lkname":_vm.ManualAname,"titname":_vm.Manualtitname,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isFshow)?_c('Firstcontrol',{attrs:{"infoList":_vm.infoList,"isIndex":_vm.isIndex,"historyname":_vm.Firstcontrolname,"titname":_vm.Firstcontroltitname,"node":_vm.Firstcontrolnode},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.nodename,"Lkname":_vm.Aname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),_c('inputVal',{ref:"inputVal",on:{"getTreeData":function($event){return _vm.DataJson(arguments)}}}),(_vm.isOshow)?_c('Observer',{attrs:{"infoList":_vm.infoList,"historyname":_vm.Observername,"node":_vm.Observernode},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isPshow)?_c('publicParam',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isPshow01)?_c('Param01',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isPshow11)?_c('Param11',{attrs:{"infoList":_vm.infoList,"datatype":_vm.historyname},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isPshow21)?_c('Param21',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isPshow31)?_c('Param31',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isPshow41)?_c('Param41',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hd"},[_c('div',{staticClass:"hjg"},[_vm._v("拱顶温度")]),_c('div',{staticClass:"hjg"},[_vm._v("蓄热能力")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex"},[_c('div',{staticClass:"famen2 fwz1"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require("@/assets/images/jflimg2.png")}})]),_c('div',{staticClass:"famen2 fwz2"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require("@/assets/images/jflimg2.png")}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rightmain"},[_c('div',{staticClass:"right-fm1"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require("../../assets/images/fam1.png")}})]),_c('div',{staticClass:"right-fm2"},[_c('img',{attrs:{"src":require("../../assets/images/fam2.png"),"alt":""}})])])}]

export { render, staticRenderFns }