<template>
  <div>
    <div class="rqlindex-conitaner" id="rsindex">
      <div class="maskbok" v-if="maskbok"></div>
      <div class="title">
        {{ projectData.project }}
        <!-- 高炉热风炉燃烧优化控制系统 -->
      </div>
      <div class="main flex">
        <div class="left_all">
          <div class="left_up flex">
            <div class="lable1">煤气管道</div>
            <div>
              <div class="famen1 jiange1">
                <img
                  :src="infoList.V_SOUREC.KX_01_B | typeFifter"
                  alt=""
                  ondragstart="return false;"
                />
              </div>
              <div class="anfa flex">
                <div
                  class="button leftx1"
                  :style="{
                    background:
                      infoList.V_GONGGONG__p__ZGXK01 &&
                      !infoList.V_GONGGONG__p__ZGXK01.TS
                        ? '#2AFC30'
                        : 'red',
                  }"
                  @click="
                    infoList.V_GONGGONG__p__ZGXK01
                      ? toDetail(
                          1,
                          'ZGXK01',
                          'V_GONGGONG__p__ZGXK01',
                          '',
                          '煤气总管压力先控'
                        )
                      : ''
                  "
                >
                  X
                </div>
                <div
                  class="button leftx2"
                  :style="{
                    background:
                      infoList.V_GONGGONG__p__MAN1 &&
                      infoList.V_GONGGONG__p__MAN1.RM == 1
                        ? '#2AFC30'
                        : 'red',
                  }"
                  @click="
                    infoList.V_GONGGONG__p__MAN1
                      ? toCompon(
                          0,
                          'AV',
                          'V_GONGGONG__p__MAN1',
                          'MAN1_AV_WF',
                          '煤气总管压力调节阀'
                        )
                      : ''
                  "
                >
                  A
                </div>
                <div class="lefttext lefttextje">
                  <div
                    @click="
                      toCompon(
                        2,
                        'KX_01_B',
                        'V_SOUREC',
                        'KX_01_B_WF',
                        '煤气总管阀调节DCS'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.V_SOUREC.KX_01_B,
                        'KX_01_B',
                        'V_SOUREC',
                        'KX_01_B_WF'
                      )
                    "
                  >
                    {{ infoList.V_SOUREC.KX_01_B }} &nbsp;%
                  </div>
                  <div
                    @click="
                      toCompon(
                        2,
                        'FVI_01_B',
                        'V_SOUREC',
                        'FVI_01_B_WF',
                        '煤气总管阀位反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.V_SOUREC.FVI_01_B,
                        'FVI_01_B',
                        'V_SOUREC',
                        'FVI_01_B_WF'
                      )
                    "
                  >
                    {{ infoList.V_SOUREC.FVI_01_B }}&nbsp;%
                  </div>
                </div>
              </div>
            </div>
            <div class="lefttext1">
              <div
                class="hjg"
                @click="
                  toCompon(
                    2,
                    'FT_01_B',
                    'V_SOUREC',
                    'FT_01_B_WF',
                    '煤气总管流量'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.V_SOUREC.FT_01_B,
                    'FT_01_B',
                    'V_SOUREC',
                    'FT_01_B_WF'
                  )
                "
              >
                {{ infoList.V_SOUREC.FT_01_B }} &nbsp;m³/h
              </div>
              <div
                class="hjg"
                @click="
                  toCompon(
                    2,
                    'PIC_01_B',
                    'V_SOUREC',
                    'PIC_01_B_WF',
                    '煤气总管压力'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.V_SOUREC.PIC_01_B,
                    'PIC_01_B',
                    'V_SOUREC',
                    'PIC_01_B_WF'
                  )
                "
              >
                {{ infoList.V_SOUREC.PIC_01_B }} &nbsp;KPa
              </div>
              <div
                class="hjg"
                @click="
                  toCompon(
                    2,
                    'TE_02_B',
                    'V_SOUREC',
                    'TE_02_B_WF',
                    '煤气总管预热后温度'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.V_SOUREC.TE_02_B,
                    'TE_02_B',
                    'V_SOUREC',
                    'TE_02_B_WF'
                  )
                "
              >
                {{ infoList.V_SOUREC.TE_02_B }}&nbsp;℃
              </div>
            </div>
            <div>
              <div class="hd">
                <div class="hjg">拱顶温度</div>
                <div class="hjg">蓄热能力</div>
              </div>
              <div>
                <div class="gdpart flex">
                  <div class="gd1">
                    <div class="htwd">1号炉</div>
                    <div class="htwdtitle">
                      <div
                        class="jiange3"
                        @click="
                          toCompon(
                            2,
                            'TE_11_B1',
                            'V_SOUREC',
                            'TE_11_B1_WF',
                            '1#热风炉拱顶温度1'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.V_SOUREC.TE_11_B1,
                            'TE_11_B1',
                            'V_SOUREC',
                            'TE_11_B1_WF'
                          )
                        "
                      >
                        {{ infoList.V_SOUREC.TE_11_B1 }}&nbsp;℃
                      </div>
                      <div
                        class="jiange3"
                        @click="
                          toCompon(
                            2,
                            'DTXR_B1',
                            'V_RCL1',
                            'DTXR_B1_WF',
                            '1#动态蓄热能力'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.V_RCL1.DTXR_B1,
                            'DTXR_B1',
                            'V_RCL1',
                            'DTXR_B1_WF'
                          )
                        "
                      >
                        {{ infoList.V_RCL1.DTXR_B1 }}&nbsp;%
                      </div>
                    </div>
                  </div>
                  <div class="gd2">
                    <div class="htwd">2号炉</div>
                    <div class="htwdtitle">
                      <div
                        class="jiange3"
                        @click="
                          toCompon(
                            2,
                            'TE_11_B2',
                            'V_SOUREC',
                            'TE_11_B2_WF',
                            '2#热风炉拱顶温度1'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.V_SOUREC.TE_11_B2,
                            'TE_11_B2',
                            'V_SOUREC',
                            'TE_11_B2_WF'
                          )
                        "
                      >
                        {{ infoList.V_SOUREC.TE_11_B2 }}&nbsp;℃
                      </div>
                      <div
                        class="jiange3"
                        @click="
                          toCompon(
                            2,
                            'DTXR_B2',
                            'V_RCL2',
                            'DTXR_B2_WF',
                            '2#动态蓄热能力'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.V_RCL2.DTXR_B2,
                            'DTXR_B2',
                            'V_RCL2',
                            'DTXR_B2_WF'
                          )
                        "
                      >
                        {{ infoList.V_RCL2.DTXR_B2 }}&nbsp;%
                      </div>
                    </div>
                  </div>
                  <div class="gd3">
                    <div class="htwd">3号炉</div>
                    <div class="htwdtitle">
                      <div
                        class="jiange3"
                        @click="
                          toCompon(
                            2,
                            'TE_11_B3',
                            'V_SOUREC',
                            'TE_11_B3_WF',
                            '3#热风炉拱顶温度1'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.V_SOUREC.TE_11_B3,
                            'TE_11_B3',
                            'V_SOUREC',
                            'TE_11_B3_WF'
                          )
                        "
                      >
                        {{ infoList.V_SOUREC.TE_11_B3 }} &nbsp;℃
                      </div>
                      <div
                        class="jiange3"
                        @click="
                          toCompon(
                            2,
                            'DTXR_B3',
                            'V_RCL3',
                            'DTXR_B3_WF',
                            '3#动态蓄热能力'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.V_RCL3.DTXR_B3,
                            'DTXR_B3',
                            'V_RCL3',
                            'DTXR_B3_WF'
                          )
                        "
                      >
                        {{ infoList.V_RCL3.DTXR_B3 }}&nbsp;%
                      </div>
                    </div>
                  </div>
                  <div class="gd4">
                    <div class="htwd">4号炉</div>
                    <div class="htwdtitle">
                      <div
                        class="jiange3"
                        @click="
                          toCompon(
                            2,
                            'TE_11_B4',
                            'V_SOUREC',
                            'TE_11_B4_WF',
                            '4#热风炉拱顶温度1'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.V_SOUREC.TE_11_B4,
                            'TE_11_B4',
                            'V_SOUREC',
                            'TE_11_B4_WF'
                          )
                        "
                      >
                        {{ infoList.V_SOUREC.TE_11_B4 }}℃
                      </div>
                      <div
                        class="jiange3"
                        @click="
                          toCompon(
                            2,
                            'DTXR_B4',
                            'V_RCL4',
                            'DTXR_B4_WF',
                            '4#动态蓄热能力'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.V_RCL4.DTXR_B4,
                            'DTXR_B4',
                            'V_RCL4',
                            'DTXR_B4_WF'
                          )
                        "
                      >
                        {{ infoList.V_RCL4.DTXR_B4 }}%
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex" style="margin-left: 0vw; margin-top: 0.8vh">
                  <div class="famen1 jiange2" @click="toCompon(7, 1)">
                    <img
                      :src="infoList.V_SOUREC.KB_21_B1 | typeFifter"
                      alt=""
                    />
                  </div>
                  <div class="famen1 jiange4" @click="toCompon(7, 2)">
                    <img
                      :src="infoList.V_SOUREC.KB_21_B2 | typeFifter"
                      alt=""
                    />
                  </div>
                  <div class="famen1 jiange5" @click="toCompon(7, 3)">
                    <img
                      :src="infoList.V_SOUREC.KB_21_B3 | typeFifter"
                      alt=""
                    />
                  </div>
                  <div class="famen1 jiange6" @click="toCompon(7, 4)">
                    <img
                      :src="infoList.V_SOUREC.KB_21_B4 | typeFifter"
                      alt=""
                    />
                  </div>
                </div>

                <div class="flex" style="margin-left: 1.8vw; margin-top: 0.5vh">
                  <div class="child-data flex">
                    <div
                      class="button"
                      :style="{
                        background:
                          infoList.V_RS__p__RSFB1__p__XK01 &&
                          !infoList.V_RS__p__RSFB1__p__XK01.TS
                            ? '#2AFC30'
                            : 'red',
                      }"
                      @click="
                        infoList.V_RS__p__RSFB1__p__XK01
                          ? toDetail(
                              1,
                              'XKA01',
                              'V_RS__p__RSFB1__p__XK01',
                              '',
                              '1#煤气流量先控'
                            )
                          : ''
                      "
                    >
                      X
                    </div>
                    <div
                      class="button"
                      style="margin-left: 0vw"
                      :style="{
                        background:
                          infoList.V_RS__p__RSFB1__p__MAN1.RM == 1
                            ? '#2AFC30'
                            : 'red',
                      }"
                      @click="
                        toCompon(
                          0,
                          'AV',
                          'V_RS__p__RSFB1__p__MAN1',
                          'MANA1_AV_WF',
                          '1#炉煤气流量调节阀'
                        )
                      "
                    >
                      A
                    </div>
                    <div class="lefttext" style="margin-left: 0.2vw">
                      <div
                        @click="
                          toCompon(
                            2,
                            'KB_11_B1',
                            'V_SOUREC',
                            'KB_11_B1_WF',
                            '1#热风炉煤气阀位调节1BCS'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.V_SOUREC.KB_11_B1,
                            'KB_11_B1',
                            'V_SOUREC',
                            'KB_11_B1_WF'
                          )
                        "
                      >
                        {{ infoList.V_SOUREC.KB_11_B1 }}&nbsp;%
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'FVI_11_B1',
                            'V_SOUREC',
                            'FVI_11_B1_WF',
                            '1#热风炉煤气阀位反馈1'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.V_SOUREC.FVI_11_B1,
                            'FVI_11_B1',
                            'V_SOUREC',
                            'FVI_11_B1_WF'
                          )
                        "
                      >
                        {{ infoList.V_SOUREC.FVI_11_B1 }}&nbsp;%
                      </div>
                    </div>
                  </div>
                  <div class="child-data flex">
                    <div
                      class="button"
                      :style="{
                        background:
                          infoList.V_RS__p__RSFB2__p__XK01 &&
                          !infoList.V_RS__p__RSFB2__p__XK01.TS
                            ? '#2AFC30'
                            : 'red',
                      }"
                      @click="
                        infoList.V_RS__p__RSFB2__p__XK01
                          ? toDetail(
                              1,
                              'XKB01',
                              'V_RS__p__RSFB2__p__XK01',
                              '',
                              '2#煤气流量先控'
                            )
                          : ''
                      "
                    >
                      X
                    </div>
                    <div
                      class="button"
                      style="margin-left: 0.1vw"
                      :style="{
                        background:
                          infoList.V_RS__p__RSFB2__p__MAN1.RM == 1
                            ? '#2AFC30'
                            : 'red',
                      }"
                      @click="
                        toCompon(
                          0,
                          'AV',
                          'V_RS__p__RSFB2__p__MAN1',
                          'MANA1_AV_WF',
                          '2#炉煤气流量调节阀'
                        )
                      "
                    >
                      A
                    </div>
                    <div class="lefttext" style="margin-left: 0.2vw">
                      <div
                        @click="
                          toCompon(
                            2,
                            'KB_11_B2',
                            'V_SOUREC',
                            'KB_11_B2_WF',
                            '2#热风炉煤气阀位调节1BCS'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.V_SOUREC.KB_11_B2,
                            'KB_11_B2',
                            'V_SOUREC',
                            'KB_11_B2_WF'
                          )
                        "
                      >
                        {{ infoList.V_SOUREC.KB_11_B2 }}&nbsp;%
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'FVI_11_B2',
                            'V_SOUREC',
                            'FVI_11_B2_WF',
                            '2#热风炉煤气阀位反馈1'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.V_SOUREC.FVI_11_B2,
                            'FVI_11_B2',
                            'V_SOUREC',
                            'FVI_11_B2_WF'
                          )
                        "
                      >
                        {{ infoList.V_SOUREC.FVI_11_B2 }}&nbsp;%
                      </div>
                    </div>
                  </div>
                  <div class="flex" style="margin-left: -0.3vw">
                    <div
                      class="button"
                      :style="{
                        background:
                          infoList.V_RS__p__RSFB3__p__XK01 &&
                          !infoList.V_RS__p__RSFB3__p__XK01.TS
                            ? '#2AFC30'
                            : 'red',
                      }"
                      @click="
                        infoList.V_RS__p__RSFB3__p__XK01
                          ? toDetail(
                              1,
                              'XKC01',
                              'V_RS__p__RSFB3__p__XK01',
                              '',
                              '3#煤气流量先控'
                            )
                          : ''
                      "
                    >
                      X
                    </div>
                    <div
                      class="button"
                      style="margin-left: 0.1vw"
                      :style="{
                        background:
                          infoList.V_RS__p__RSFB3__p__MAN1.RM == 1
                            ? '#2AFC30'
                            : 'red',
                      }"
                      @click="
                        toCompon(
                          0,
                          'AV',
                          'V_RS__p__RSFB3__p__MAN1',
                          'MANA1_AV_WF',
                          '3#炉煤气流量调节阀'
                        )
                      "
                    >
                      A
                    </div>
                    <div class="lefttext" style="margin-left: 0.2vw">
                      <div
                        @click="
                          toCompon(
                            2,
                            'KB_11_B3',
                            'V_SOUREC',
                            'KB_11_B3_WF',
                            '3#热风炉煤气阀位调节1BCS'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.V_SOUREC.KB_11_B3,
                            'KB_11_B3',
                            'V_SOUREC',
                            'KB_11_B3_WF'
                          )
                        "
                      >
                        {{ infoList.V_SOUREC.KB_11_B3 }}&nbsp;%
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'FVI_11_B3',
                            'V_SOUREC',
                            'FVI_11_B3_WF',
                            '3#热风炉煤气阀位反馈1'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.V_SOUREC.FVI_11_B3,
                            'FVI_11_B3',
                            'V_SOUREC',
                            'FVI_11_B3_WF'
                          )
                        "
                      >
                        {{ infoList.V_SOUREC.FVI_11_B3 }}&nbsp;%
                      </div>
                    </div>
                  </div>
                  <div class="flex" style="margin-left: -0.3vw">
                    <div
                      class="button"
                      :style="{
                        background:
                          infoList.V_RS__p__RSFB4__p__XK01 &&
                          !infoList.V_RS__p__RSFB4__p__XK01.TS
                            ? '#2AFC30'
                            : 'red',
                      }"
                      @click="
                        infoList.V_RS__p__RSFB4__p__XK01
                          ? toDetail(
                              1,
                              'XKD01',
                              'V_RS__p__RSFB4__p__XK01',
                              '',
                              '4#煤气流量先控'
                            )
                          : ''
                      "
                    >
                      X
                    </div>
                    <div
                      class="button"
                      style="margin-left: 0.1vw"
                      :style="{
                        background:
                          infoList.V_RS__p__RSFB4__p__MAN1.RM == 1
                            ? '#2AFC30'
                            : 'red',
                      }"
                      @click="
                        toCompon(
                          0,
                          'AV',
                          'V_RS__p__RSFB4__p__MAN1',
                          'MANA1_AV_WF',
                          '4#炉煤气流量调节阀'
                        )
                      "
                    >
                      A
                    </div>
                    <div class="lefttext" style="margin-left: 0.2vw">
                      <div
                        @click="
                          toCompon(
                            2,
                            'KB_11_B4',
                            'V_SOUREC',
                            'KB_11_B4_WF',
                            '4#热风炉煤气阀位调节1BCS'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.V_SOUREC.KB_11_B4,
                            'KB_11_B4',
                            'V_SOUREC',
                            'KB_11_B4_WF'
                          )
                        "
                      >
                        {{ infoList.V_SOUREC.KB_11_B4 }}&nbsp;%
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'FVI_11_B4',
                            'V_SOUREC',
                            'FVI_11_B4_WF',
                            '4#热风炉煤气阀位反馈1'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.V_SOUREC.FVI_11_B4,
                            'FVI_11_B4',
                            'V_SOUREC',
                            'FVI_11_B4_WF'
                          )
                        "
                      >
                        {{ infoList.V_SOUREC.FVI_11_B4 }}&nbsp;%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="left_middle">
            <div class="lefttext1 flex">
              <div>
                <div
                  class="hjg"
                  @click="
                    toCompon(
                      2,
                      'TE_03_B',
                      'V_SOUREC',
                      'TE_03_B_WF',
                      '助燃风总管预热后温度'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.V_SOUREC.TE_03_B,
                      'TE_03_B',
                      'V_SOUREC',
                      'TE_03_B_WF'
                    )
                  "
                >
                  {{ infoList.V_SOUREC.TE_03_B }}&nbsp;℃
                </div>
                <div
                  class="hjg"
                  @click="
                    toCompon(
                      2,
                      'PIC_02_B',
                      'V_SOUREC',
                      'PIC_02_B_WF',
                      '助燃风总管压力'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.V_SOUREC.PIC_02_B,
                      'PIC_02_B',
                      'V_SOUREC',
                      'PIC_02_B_WF'
                    )
                  "
                >
                  {{ infoList.V_SOUREC.PIC_02_B }}&nbsp;KPa
                </div>
                <div
                  class="hjg"
                  style="color: #5ab5ce"
                  @click="
                    infoList.V_GONGGONG__p__ZGXK02
                      ? toCompon(
                          2,
                          'SP',
                          'V_GONGGONG__p__ZGXK02',
                          'ZGXK02_SP_WF',
                          '1#风机总管压力先控'
                        )
                      : ''
                  "
                  @dblclick="
                    Cclick(
                      infoList.V_GONGGONG__p__ZGXK02.SP,
                      'SP',
                      'V_GONGGONG__p__ZGXK02',
                      'ZGXK02_SP_WF'
                    )
                  "
                >
                  {{
                    infoList.V_GONGGONG__p__ZGXK02
                      ? infoList.V_GONGGONG__p__ZGXK02.SP
                      : ""
                  }}&nbsp;KPa
                </div>
              </div>
            </div>
            <div class="left_button flex">
              <div class="xinx1 fl">
                <div class="flex">
                  <div>
                    <div class="flex">
                      <div
                        class="button"
                        :style="{
                          background:
                            infoList.V_RS__p__RSFB1__p__XK02 &&
                            !infoList.V_RS__p__RSFB1__p__XK02.TS
                              ? '#2AFC30'
                              : 'red',
                        }"
                        @click="
                          infoList.V_RS__p__RSFB1__p__XK02
                            ? toDetail(
                                1,
                                'XKA02',
                                'V_RS__p__RSFB1__p__XK02',
                                '',
                                '1#空气流量先控'
                              )
                            : ''
                        "
                      >
                        X
                      </div>
                      <div
                        class="button"
                        style="margin-left: 0.1vw"
                        :style="{
                          background:
                            infoList.V_RS__p__RSFB1__p__MAN2.RM == 1
                              ? '#2AFC30'
                              : 'red',
                        }"
                        @click="
                          toCompon(
                            0,
                            'AV ',
                            'V_RS__p__RSFB1__p__MAN2',
                            'MANA2_AV_WF',
                            '1#炉空气流量调节阀'
                          )
                        "
                      >
                        A
                      </div>
                    </div>
                    <div class="flex">
                      <div
                        class="button"
                        style="margin-top: 0.3vh"
                        :style="{
                          background:
                            infoList.V_RS__p__RSFB1__p__XK04 &&
                            !infoList.V_RS__p__RSFB1__p__XK04.TS
                              ? '#2AFC30'
                              : 'red',
                        }"
                        @click="
                          infoList.V_RS__p__RSFB1__p__XK04
                            ? toDetail(
                                1,
                                'XKA04',
                                'V_RS__p__RSFB1__p__XK04',
                                '',
                                '1#废气温度调节先控'
                              )
                            : ''
                        "
                      >
                        X
                      </div>
                      <div
                        class="button"
                        style="margin-left: 0.1vw; margin-top: 0.3vh"
                        :style="{
                          background:
                            infoList.V_RS__p__RSFB1__p__XK05 &&
                            !infoList.V_RS__p__RSFB1__p__XK05.TS
                              ? '#2AFC30'
                              : 'red',
                        }"
                        @click="
                          infoList.V_RS__p__RSFB1__p__XK05
                            ? toDetail(
                                1,
                                'XKA05',
                                'V_RS__p__RSFB1__p__XK05',
                                '',
                                '1#拱顶温度调节空燃比先控'
                              )
                            : ''
                        "
                      >
                        X
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="lefttext" style="padding-left: 0.1vw">
                      <div
                        @click="
                          toCompon(
                            2,
                            'KB_21_B1',
                            'V_SOUREC',
                            'KB_21_B1_WF',
                            '1#热风炉助燃风阀调节1BCS'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.V_SOUREC.KB_21_B1,
                            'KB_21_B1',
                            'V_SOUREC',
                            'KB_21_B1_WF'
                          )
                        "
                      >
                        {{ infoList.V_SOUREC.KB_21_B1 }}&nbsp;%
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'FVI_21_B1',
                            'V_SOUREC',
                            'FVI_21_B1_WF',
                            '1#热风炉助燃风阀位反馈1'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.V_SOUREC.FVI_21_B1,
                            'FVI_21_B1',
                            'V_SOUREC',
                            'FVI_21_B1_WF'
                          )
                        "
                      >
                        {{ infoList.V_SOUREC.FVI_21_B1 }}&nbsp;%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="xinx2 flex">
                <div class="flex">
                  <div>
                    <div class="flex">
                      <div
                        class="button"
                        :style="{
                          background:
                            infoList.V_RS__p__RSFB2__p__XK02 &&
                            !infoList.V_RS__p__RSFB2__p__XK02.TS
                              ? '#2AFC30'
                              : 'red',
                        }"
                        @click="
                          infoList.V_RS__p__RSFB2__p__XK02
                            ? toDetail(
                                1,
                                'XKB02',
                                'V_RS__p__RSFB2__p__XK02',
                                '',
                                '2#空气流量先控'
                              )
                            : ''
                        "
                      >
                        X
                      </div>
                      <div
                        class="button"
                        style="margin-left: 0.1vw"
                        :style="{
                          background:
                            infoList.V_RS__p__RSFB2__p__MAN2 &&
                            infoList.V_RS__p__RSFB2__p__MAN2.RM == 1
                              ? '#2AFC30'
                              : 'red',
                        }"
                        @click="
                          infoList.V_RS__p__RSFB2__p__MAN2
                            ? toCompon(
                                0,
                                'AV',
                                'V_RS__p__RSFB2__p__MAN2',
                                'MANA2_AV_WF',
                                '2#炉空气流量调节阀'
                              )
                            : ''
                        "
                      >
                        A
                      </div>
                    </div>

                    <div class="flex">
                      <div
                        class="button"
                        style="margin-top: 0.3vh"
                        :style="{
                          background:
                            infoList.V_RS__p__RSFB2__p__XK04 &&
                            !infoList.V_RS__p__RSFB2__p__XK04.TS
                              ? '#2AFC30'
                              : 'red',
                        }"
                        @click="
                          infoList.V_RS__p__RSFB2__p__XK04
                            ? toDetail(
                                1,
                                'XKB04',
                                'V_RS__p__RSFB2__p__XK04',
                                '',
                                '2#废气温度调节先控'
                              )
                            : ''
                        "
                      >
                        X
                      </div>
                      <div
                        class="button"
                        style="margin-left: 0.1vw; margin-top: 0.3vh"
                        :style="{
                          background:
                            infoList.V_RS__p__RSFB2__p__XK05 &&
                            infoList.V_RS__p__RSFB2__p__XK05.RM == 1
                              ? '#2AFC30'
                              : 'red',
                        }"
                        @click="
                          infoList.V_RS__p__RSFB2__p__XK05
                            ? toDetail(
                                1,
                                'XKB05',
                                'V_RS__p__RSFB2__p__XK05',
                                '',
                                '2#拱顶温度调节空燃比先控'
                              )
                            : ''
                        "
                      >
                        X
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="lefttext" style="padding-left: 0.1vw">
                    <div
                      @click="
                        toCompon(
                          2,
                          'KB_21_B2',
                          'V_SOUREC',
                          'KB_21_B2_WF',
                          '2#热风炉助燃风阀调节1BCS'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.V_SOUREC.KB_21_B2,
                          'KB_21_B2',
                          'V_SOUREC',
                          'KB_21_B2_WF'
                        )
                      "
                    >
                      {{ infoList.V_SOUREC.KB_21_B2 }}&nbsp;%
                    </div>
                    <div
                      @click="
                        toCompon(
                          2,
                          'FVI_21_B2',
                          'V_SOUREC',
                          'FVI_21_B2_WF',
                          '2#热风炉助燃风阀位反馈1'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.V_SOUREC.FVI_21_B2,
                          'FVI_21_B2',
                          'V_SOUREC',
                          'FVI_21_B2_WF'
                        )
                      "
                    >
                      {{ infoList.V_SOUREC.FVI_21_B2 }}&nbsp;%
                    </div>
                  </div>
                </div>
              </div>
              <div class="xinx3 flex">
                <div class="flex">
                  <div>
                    <div class="flex">
                      <div
                        class="button"
                        :style="{
                          background:
                            infoList.V_RS__p__RSFB3__p__XK02 &&
                            !infoList.V_RS__p__RSFB3__p__XK02.TS
                              ? '#2AFC30'
                              : 'red',
                        }"
                        @click="
                          infoList.V_RS__p__RSFB3__p__XK02
                            ? toDetail(
                                1,
                                'XKC02',
                                'V_RS__p__RSFB3__p__XK02',
                                '',
                                '3#空气流量先控'
                              )
                            : ''
                        "
                      >
                        X
                      </div>
                      <div
                        class="button"
                        style="margin-left: 0.1vw"
                        :style="{
                          background:
                            infoList.V_RS__p__RSFB3__p__MAN2 &&
                            infoList.V_RS__p__RSFB3__p__MAN2.RM == 1
                              ? '#2AFC30'
                              : 'red',
                        }"
                        @click="
                          infoList.V_RS__p__RSFB3__p__MAN2
                            ? toCompon(
                                0,
                                'AV',
                                'V_RS__p__RSFB3__p__MAN2',
                                'MANC2_AV_WF',
                                '3#炉空气流量调节阀'
                              )
                            : ''
                        "
                      >
                        A
                      </div>
                    </div>
                    <div class="flex">
                      <div
                        class="button"
                        style="margin-top: 0.3vh"
                        :style="{
                          background:
                            infoList.V_RS__p__RSFB3__p__XK04 &&
                            !infoList.V_RS__p__RSFB3__p__XK04.TS
                              ? '#2AFC30'
                              : 'red',
                        }"
                        @click="
                          infoList.V_RS__p__RSFB3__p__XK04
                            ? toDetail(
                                1,
                                'XKC04',
                                'V_RS__p__RSFB3__p__XK04',
                                '',
                                '3#废气温度调节先控'
                              )
                            : ''
                        "
                      >
                        X
                      </div>
                      <div
                        class="button"
                        style="margin-left: 0.1vw; margin-top: 0.3vh"
                        :style="{
                          background:
                            infoList.V_RS__p__RSFB3__p__XK05 &&
                            infoList.V_RS__p__RSFB3__p__XK05.RM == 1
                              ? '#2AFC30'
                              : 'red',
                        }"
                        @click="
                          infoList.V_RS__p__RSFB3__p__XK05
                            ? toDetail(
                                1,
                                'XKC05',
                                'V_RS__p__RSFB3__p__XK05',
                                '',
                                '3#拱顶温度调节空燃比先控'
                              )
                            : ''
                        "
                      >
                        X
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div class="lefttext" style="padding-left: 0.1vw">
                    <div
                      @click="
                        toCompon(
                          2,
                          'KB_21_B3',
                          'V_SOUREC',
                          'KB_21_B3_WF',
                          '3#热风炉助燃风阀调节1BCS'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.V_SOUREC.KB_21_B3,
                          'KB_21_B3',
                          'V_SOUREC',
                          'KB_21_B3_WF'
                        )
                      "
                    >
                      {{ infoList.V_SOUREC.KB_21_B3 }}&nbsp;%
                    </div>
                    <div
                      @click="
                        toCompon(
                          2,
                          'FVI_21_B3',
                          'V_SOUREC',
                          'FVI_21_B3_WF',
                          '3#热风炉助燃风阀位反馈1'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.V_SOUREC.FVI_21_B3,
                          'FVI_21_B3',
                          'V_SOUREC',
                          'FVI_21_B3_WF'
                        )
                      "
                    >
                      {{ infoList.V_SOUREC.FVI_21_B3 }}&nbsp;%
                    </div>
                  </div>
                </div>
              </div>
              <div class="xinx4 flex">
                <div class="flex">
                  <div>
                    <div class="flex">
                      <div
                        class="button"
                        :style="{
                          background:
                            infoList.V_RS__p__RSFB4__p__XK02 &&
                            !infoList.V_RS__p__RSFB4__p__XK02.TS
                              ? '#2AFC30'
                              : 'red',
                        }"
                        @click="
                          infoList.V_RS__p__RSFB4__p__XK02
                            ? toDetail(
                                1,
                                'XKD02',
                                'V_RS__p__RSFB4__p__XK02',
                                '',
                                '4#空气流量先控'
                              )
                            : ''
                        "
                      >
                        X
                      </div>
                      <div
                        class="button"
                        style="margin-left: 0.1vw"
                        :style="{
                          background:
                            infoList.V_RS__p__RSFB4__p__MAN2 &&
                            infoList.V_RS__p__RSFB4__p__MAN2.RM == 1
                              ? '#2AFC30'
                              : 'red',
                        }"
                        @click="
                          infoList.V_RS__p__RSFB4__p__MAN2
                            ? toCompon(
                                0,
                                'AV',
                                'V_RS__p__RSFB4__p__MAN2',
                                'MAND2_AV_WF',
                                '4#炉空气流量调节阀'
                              )
                            : ''
                        "
                      >
                        A
                      </div>
                    </div>
                    <div class="flex">
                      <div
                        class="button"
                        style="margin-top: 0.3vh"
                        :style="{
                          background:
                            infoList.V_RS__p__RSFB4__p__XK04 &&
                            !infoList.V_RS__p__RSFB4__p__XK04.TS
                              ? '#2AFC30'
                              : 'red',
                        }"
                        @click="
                          infoList.V_RS__p__RSFB4__p__XK04
                            ? toDetail(
                                1,
                                'XKD04',
                                'V_RS__p__RSFB4__p__XK04',
                                '',
                                '4#废气温度调节先控'
                              )
                            : ''
                        "
                      >
                        X
                      </div>
                      <div
                        class="button"
                        style="margin-left: 0.1vw; margin-top: 0.3vh"
                        :style="{
                          background:
                            infoList.V_RS__p__RSFB4__p__XK05 &&
                            infoList.V_RS__p__RSFB4__p__XK05.RM == 1
                              ? '#2AFC30'
                              : 'red',
                        }"
                        @click="
                          infoList.V_RS__p__RSFB4__p__XK05
                            ? toDetail(
                                1,
                                'XKD05',
                                'V_RS__p__RSFB4__p__XK05',
                                '',
                                '4#拱顶温度调节空燃比先控'
                              )
                            : ''
                        "
                      >
                        X
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="lefttext fl" style="padding-left: 0.1vw">
                      <div
                        @click="
                          toCompon(
                            2,
                            'KB_21_B4',
                            'V_SOUREC',
                            'KB_21_B4_WF',
                            '4#热风炉助燃风阀调节1BCS'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.V_SOUREC.KB_21_B4,
                            'KB_21_B4',
                            'V_SOUREC',
                            'KB_21_B4_WF'
                          )
                        "
                      >
                        {{ infoList.V_SOUREC.KB_21_B4 }}&nbsp;%
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'FVI_21_B4',
                            'V_SOUREC',
                            'FVI_21_B4_WF',
                            '4#热风炉助燃风阀位反馈1'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.V_SOUREC.FVI_21_B4,
                            'FVI_21_B4',
                            'V_SOUREC',
                            'FVI_21_B4_WF'
                          )
                        "
                      >
                        {{ infoList.V_SOUREC.FVI_21_B4 }}&nbsp;%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex" style="margin-left: 16.7vw; margin-top: 0.5vh">
              <div class="famen1" @click="toCompon(7, 1)">
                <img :src="infoList.V_SOUREC.KB_21_B1 | typeFifter" alt="" />
              </div>
              <div class="famen1 jiange4" @click="toCompon(7, 2)">
                <img :src="infoList.V_SOUREC.KB_21_B2 | typeFifter" alt="" />
              </div>
              <div class="famen1 jiange5" @click="toCompon(7, 3)">
                <img :src="infoList.V_SOUREC.KB_21_B3 | typeFifter" alt="" />
              </div>
              <div class="famen1 jiange6" @click="toCompon(7, 4)">
                <img :src="infoList.V_SOUREC.KB_21_B4 | typeFifter" alt="" />
              </div>
            </div>
          </div>
          <div class="left_down flex">
            <div class="left_left">
              <div class="lable2">空气管道</div>
              <div class="flex">
                <div class="famen2 fwz1">
                  <img
                    src="~@/assets/images/jflimg2.png"
                    style="width: 100%; height: 100%"
                  />
                </div>
                <div class="famen2 fwz2">
                  <img
                    src="~@/assets/images/jflimg2.png"
                    style="width: 100%; height: 100%"
                  />
                </div>
              </div>
              <div class="flex">
                <div class="xinx5 flex">
                  <div class="flex">
                    <div
                      class="button"
                      :style="{
                        background:
                          infoList.V_GONGGONG__p__ZGXK02 &&
                          !infoList.V_GONGGONG__p__ZGXK02.TS
                            ? '#2AFC30'
                            : 'red',
                      }"
                      @click="
                        infoList.V_GONGGONG__p__ZGXK02
                          ? toDetail(
                              1,
                              'ZGXK02',
                              'V_GONGGONG__p__ZGXK02',
                              '',
                              '1#风机总管压力先控'
                            )
                          : ''
                      "
                    >
                      X
                    </div>
                    <div
                      class="button"
                      style="margin-left: 0.1vw"
                      :style="{
                        background:
                          infoList.V_GONGGONG__p__MAN2 &&
                          infoList.V_GONGGONG__p__MAN2.RM == 1
                            ? '#2AFC30'
                            : 'red',
                      }"
                      @click="
                        infoList.V_GONGGONG__p__MAN2
                          ? toCompon(
                              0,
                              'AV',
                              'V_GONGGONG__p__MAN2',
                              'MANA2_AV_WF',
                              '1#助燃风总管压力调节阀'
                            )
                          : ''
                      "
                    >
                      A
                    </div>
                  </div>
                  <div
                    class="lefttext"
                    style="padding-left: 0.2vw; margin-top: 0.3vh"
                  >
                    <div
                      @click="
                        toCompon(
                          2,
                          'KX_02_B',
                          'V_SOUREC',
                          'KX_02_B_WF',
                          '助燃风总管1阀调节DCS'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.V_SOUREC.KX_02_B,
                          'KX_02_B',
                          'V_SOUREC',
                          'KX_02_B_WF'
                        )
                      "
                    >
                      {{ infoList.V_SOUREC.KX_02_B }}&nbsp;%
                    </div>
                    <div
                      @click="
                        toCompon(
                          2,
                          'FVI_02_B',
                          'V_SOUREC',
                          'FVI_02_B_WF',
                          '助燃风总管1阀位反馈'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.V_SOUREC.FVI_02_B,
                          'FVI_02_B',
                          'V_SOUREC',
                          'FVI_02_B_WF'
                        )
                      "
                    >
                      {{ infoList.V_SOUREC.FVI_02_B }}&nbsp;%
                    </div>
                  </div>
                </div>
                <div class="xinx6 flex">
                  <div class="flex">
                    <div
                      class="button"
                      :style="{
                        background:
                          infoList.V_GONGGONG__p__ZGXK03 &&
                          !infoList.V_GONGGONG__p__ZGXK03.TS
                            ? '#2AFC30'
                            : 'red',
                      }"
                      @click="
                        infoList.V_GONGGONG__p__ZGXK02
                          ? toDetail(
                              1,
                              'ZGXK03',
                              'V_GONGGONG__p__ZGXK03',
                              '',
                              '2#风机总管压力先控'
                            )
                          : ''
                      "
                    >
                      X
                    </div>
                    <div
                      class="button"
                      style="margin-left: 0.1vw"
                      :style="{
                        background:
                          infoList.V_GONGGONG__p__MAN2 &&
                          infoList.V_GONGGONG__p__MAN2.RM == 1
                            ? '#2AFC30'
                            : 'red',
                      }"
                      @click="
                        infoList.V_GONGGONG__p__MAN3
                          ? toCompon(
                              0,
                              'AV',
                              'V_GONGGONG__p__MAN3',
                              'MAN3_AV_WF',
                              '2#助燃风总管压力调节阀'
                            )
                          : ''
                      "
                    >
                      A
                    </div>
                  </div>
                  <div
                    class="lefttext"
                    style="padding-left: 0.2vw; margin-top: 0.5vh"
                  >
                    <div
                      @click="
                        toCompon(
                          2,
                          'KX_03_B',
                          'V_SOUREC',
                          'KX_03_B_WF',
                          '助燃风总管2阀调节DCS'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.V_SOUREC.KX_03_B,
                          'KX_03_B',
                          'V_SOUREC',
                          'KX_03_B_WF'
                        )
                      "
                    >
                      {{ infoList.V_SOUREC.KX_03_B }}&nbsp;%
                    </div>
                    <div
                      @click="
                        toCompon(
                          2,
                          'FVI_03_B',
                          'V_SOUREC',
                          'FVI_03_B_WF',
                          '助燃风总管2阀位反馈'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.V_SOUREC.FVI_03_B,
                          'FVI_03_B',
                          'V_SOUREC',
                          'FVI_03_B_WF'
                        )
                      "
                    >
                      {{ infoList.V_SOUREC.FVI_03_B }}&nbsp;%
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="lable2">空气管道</div> -->
              <div class="lable3">废气管道</div>
            </div>
            <div class="left_right">
              <div class="titlable">单位：min</div>
              <div class="biaoge1">
                <el-table
                  :data="tableData"
                  border
                  :header-cell-style="headerStyle"
                  :row-style="{ height: '1.5vh', background: 'transparent' }"
                  :cell-style="{
                    padding: '0px',
                    borderColor: '#0f3747',
                  }"
                  style="width: 35vw; background-color: transparent"
                >
                  <el-table-column
                    prop="number"
                    label="1号炉"
                    height="1vh"
                    align="center"
                  >
                    <template scope="scope">
                      <div
                        class="wzcolor"
                        v-if="scope.$index == 0"
                        @click="
                          toCompon(
                            2,
                            'SLSJ_B1',
                            'V_SOUREC',
                            'SLSJ_B1_WF',
                            '1#炉烧炉时间设定'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.V_SOUREC.SLSJ_B1,
                            'SLSJ_B1',
                            'V_SOUREC',
                            'SLSJ_B1_WF'
                          )
                        "
                      >
                        <span
                          :style="{
                            color: infoList.V_STATE.SLKG1 ? '#F32028' : '#fff',
                          }"
                          style="display: inline-block; padding-right: 1vw font-size:1.7vh"
                        >
                          烧炉</span
                        >
                        {{ infoList.V_SOUREC.SLSJ_B1 }}
                      </div>
                      <div class="wzcolor" v-if="scope.$index == 1">
                        <span
                          :style="{
                            color: infoList.V_STATE.BLKG1 ? '#FDF061' : '#fff',
                          }"
                          style="display: inline-block; padding-left: 0px"
                        >
                          焖炉</span
                        >
                      </div>
                      <div
                        class="wzcolor"
                        v-if="scope.$index == 2"
                        @click="
                          toCompon(
                            2,
                            'SFSJ_B1',
                            'V_TJ1',
                            'SFSJ_B1_WF',
                            '1#本次送风时间'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.V_TJ1.SFSJ_B1,
                            'SFSJ_B1',
                            'V_TJ1',
                            'SFSJ_B1_WF'
                          )
                        "
                      >
                        <span
                          :style="{
                            color: infoList.V_STATE.SFKG1 ? 'green' : '#fff',
                          }"
                          style="display: inline-block; padding-right: 1vw"
                        >
                          送风</span
                        >
                        {{ infoList.V_TJ1.SFSJ_B1 }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="number1"
                    label="2号炉"
                    align="center"
                    width=""
                  >
                    <template scope="scope">
                      <div
                        class="wzcolor"
                        v-if="scope.$index == 0"
                        @click="
                          toCompon(
                            2,
                            'SLSJ_B2',
                            'V_SOUREC',
                            'SLSJ_B2_WF',
                            '2#炉烧炉时间设定'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.V_SOUREC.SLSJ_B2,
                            'SLSJ_B2',
                            'V_SOUREC',
                            'SLSJ_B2_WF'
                          )
                        "
                      >
                        <span
                          :style="{
                            color: infoList.V_STATE.SLKG2 ? '#F32028' : '#fff',
                          }"
                          style="
                            color: #f32028;
                            display: inline-block;
                            padding-right: 15px;
                          "
                          >烧炉</span
                        >
                        {{ infoList.V_SOUREC.SLSJ_B2 }}
                      </div>
                      <div class="wzcolor" v-if="scope.$index == 1">
                        <span
                          :style="{
                            color: infoList.V_STATE.BLKG2 ? '#FDF061' : '#fff',
                          }"
                          style="display: inline-block; padding-right: 15px"
                          >焖炉</span
                        >
                      </div>
                      <div
                        class="wzcolor"
                        v-if="scope.$index == 2"
                        @click="
                          toCompon(
                            2,
                            'SFSJ_B2',
                            'V_TJ2',
                            'SFSJ_B2_WF',
                            '2#本次送风时间'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.V_TJ2.SFSJ_B2,
                            'SFSJ_B2',
                            'V_TJ2',
                            'SFSJ_B2_WF'
                          )
                        "
                      >
                        <span
                          :style="{
                            color: infoList.V_STATE.SFKG2 ? 'green' : '#fff',
                          }"
                          style="display: inline-block; padding-right: 15px"
                          >送风</span
                        >
                        {{ infoList.V_TJ2.SFSJ_B2 }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="number2"
                    align="center"
                    label="3号炉"
                    width=""
                  >
                    <template scope="scope">
                      <div
                        class="wzcolor"
                        v-if="scope.$index == 0"
                        @click="
                          toCompon(
                            2,
                            'SLSJ_B3',
                            'V_SOUREC',
                            'SLSJ_B3_WF',
                            '3#炉烧炉时间设定'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.V_SOUREC.SLSJ_B3,
                            'SLSJ_B3',
                            'V_SOUREC',
                            'SLSJ_B3_WF'
                          )
                        "
                      >
                        <span
                          :style="{
                            color: infoList.V_STATE.SLKG3 ? '#F32028' : '#fff',
                          }"
                          style="display: inline-block; padding-right: 15px"
                          >烧炉</span
                        >
                        {{ infoList.V_SOUREC.SLSJ_B3 }}
                      </div>
                      <div class="wzcolor" v-if="scope.$index == 1">
                        <span
                          :style="{
                            color: infoList.V_STATE.BLKG3 ? '#FDF061' : '#fff',
                          }"
                          style="display: inline-block; padding-right: 15px"
                          >焖炉</span
                        >
                      </div>
                      <div
                        class="wzcolor"
                        v-if="scope.$index == 2"
                        @click="
                          toCompon(
                            2,
                            'SFSJ_B3',
                            'V_TJ3',
                            'SFSJ_B3_WF',
                            '3#本次送风时间'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.V_TJ3.SFSJ_B3,
                            'SFSJ_B3',
                            'V_TJ3',
                            'SFSJ_B3_WF'
                          )
                        "
                      >
                        <span
                          :style="{
                            color: infoList.V_STATE.SFKG3 ? 'green' : '#fff',
                          }"
                          style="display: inline-block; padding-right: 15px"
                          >送风</span
                        >
                        {{ infoList.V_TJ3.SFSJ_B3 }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="number3"
                    align="center"
                    label="4号炉"
                    width=""
                  >
                    <template scope="scope">
                      <div
                        class="wzcolor"
                        v-if="scope.$index == 0"
                        @click="
                          toCompon(
                            2,
                            'SLSJ_B4',
                            'V_SOUREC',
                            'SLSJ_B4_WF',
                            '4#炉烧炉时间设定'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.V_SOUREC.SLSJ_B4,
                            'SLSJ_B4',
                            'V_SOUREC',
                            'SLSJ_B4_WF'
                          )
                        "
                      >
                        <span
                          :style="{
                            color: infoList.V_STATE.SLKG4 ? '#F32028' : '#fff',
                          }"
                          style="display: inline-block; padding-right: 15px"
                          >烧炉</span
                        >{{ infoList.V_SOUREC.SLSJ_B4 }}
                      </div>
                      <div class="wzcolor" v-if="scope.$index == 1">
                        <span
                          :style="{
                            color: infoList.V_STATE.BLKG4 ? '#FDF061' : '#fff',
                          }"
                          style="display: inline-block; padding-right: 15px"
                          >焖炉</span
                        >
                      </div>
                      <div
                        class="wzcolor"
                        v-if="scope.$index == 2"
                        @click="
                          toCompon(
                            2,
                            'SFSJ_B4',
                            'V_TJ4',
                            'SFSJ_B4_WF',
                            '4#本次送风时间'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.V_TJ4.SFSJ_B4,
                            'SFSJ_B4',
                            'V_TJ4',
                            'SFSJ_B4_WF'
                          )
                        "
                      >
                        <span
                          :style="{
                            color: infoList.V_STATE.SFKG4 ? 'green' : '#fff',
                          }"
                          style="display: inline-block; padding-right: 15px"
                          >送风</span
                        >
                        {{ infoList.V_TJ4.SFSJ_B4 }}
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>

              <div class="biaoge2">
                <el-table
                  :data="tableData2"
                  border
                  :header-cell-style="headerStyle"
                  :row-style="{ height: '1.8vh', background: 'transparent' }"
                  :cell-style="{ padding: '1px', borderColor: '#0f3747' }"
                  style="
                    width: 35vw;
                    height: 22vh;
                    margin-top: 1vh;
                    background-color: transparent;
                  "
                >
                  <el-table-column
                    prop="number"
                    label=""
                    align="left"
                    width="130"
                  >
                    <template scope="scope">
                      <span class="wzcolor1" style="color: #5ca4a6">{{
                        scope.row.number
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="number1" label="1号炉" align="center">
                    <template scope="scope">
                      <span
                        class="wzcolor1"
                        :style="
                          scope.$index == 0
                            ? 'color:#00b4ff;cursor: pointer;'
                            : ''
                        "
                        @click="
                          scope.$index == 0
                            ? toIpt(
                                infoList.V_SP.SP11,
                                '1号炉烧炉时间',
                                'SP11',
                                'V_SP'
                              )
                            : scope.$index == 2
                            ? toCompon(
                                2,
                                'TE_13_B1',
                                'V_SOUREC',
                                'TE_13_B1_WF',
                                '1#热风炉废气温度'
                              )
                            : scope.$index == 3
                            ? toCompon(
                                2,
                                'FQXL_B1',
                                'V_TJ1',
                                'FQXL_B1_WF',
                                '1#废气斜率'
                              )
                            : scope.$index == 4
                            ? toCompon(
                                2,
                                'YCFQWD_B1',
                                'V_RS1',
                                'YCFQWD_B1_WF',
                                '1#炉预测废气温度'
                              )
                            : ''
                        "
                        @dblclick="
                          scope.$index == 2
                            ? Cclick(
                                infoList.V_SOUREC.TE_13_B1,
                                'TE_13_B1',
                                'V_SOUREC',
                                'TE_13_B1_WF'
                              )
                            : scope.$index == 3
                            ? Cclick(
                                infoList.V_TJ1.FQXL_B1,
                                'FQXL_B1',
                                'V_TJ1',
                                'FQXL_B1_WF'
                              )
                            : scope.$index == 4
                            ? Cclick(
                                infoList.V_RS1.YCFQWD_B1,
                                'YCFQWD_B1',
                                'V_RS1',
                                'YCFQWD_B1_WF'
                              )
                            : ''
                        "
                      >
                        {{
                          scope.$index == 0
                            ? infoList.V_SP.SP11
                            : scope.$index == 1 && infoList.V_RS1.SLSJ_S_B1 >= 0
                            ? infoList.V_RS1.SLSJ_S_B1
                            : scope.$index == 2
                            ? infoList.V_SOUREC.TE_13_B1
                            : scope.$index == 3
                            ? infoList.V_TJ1.FQXL_B1
                            : scope.$index == 4
                            ? infoList.V_RS1.YCFQWD_B1
                            : "0"
                        }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="number2" label="2号炉" align="center">
                    <template scope="scope">
                      <span
                        class="wzcolor1"
                        :style="
                          scope.$index == 0
                            ? 'color:#00b4ff;cursor: pointer;'
                            : ''
                        "
                        @click="
                          scope.$index == 0
                            ? toIpt(
                                infoList.V_SP.SP21,
                                '2号炉烧炉时间',
                                'SP21',
                                'V_SP'
                              )
                            : scope.$index == 2
                            ? toCompon(
                                2,
                                'TE_13_B2',
                                'V_SOUREC',
                                'TE_13_B2_WF',
                                '2#热风炉废气温度'
                              )
                            : scope.$index == 3
                            ? toCompon(
                                2,
                                'FQXL_B2',
                                'V_TJ2',
                                'FQXL_B2_WF',
                                '2#废气斜率'
                              )
                            : scope.$index == 4
                            ? toCompon(
                                2,
                                'YCFQWD_B2',
                                'V_RS2',
                                'YCFQWD_B2_WF',
                                '2#炉预测废气温度'
                              )
                            : ''
                        "
                        @dblclick="
                          scope.$index == 2
                            ? Cclick(
                                infoList.V_SOUREC.TE_13_B2,
                                'TE_13_B2',
                                'V_SOUREC',
                                'TE_13_B2_WF'
                              )
                            : scope.$index == 3
                            ? Cclick(
                                infoList.V_TJ2.FQXL_B2,
                                'FQXL_B2',
                                'V_TJ2',
                                'FQXL_B2_WF'
                              )
                            : scope.$index == 4
                            ? Cclick(
                                infoList.V_RS2.YCFQWD_B2,
                                'YCFQWD_B2',
                                'V_RS2',
                                'YCFQWD_B2_WF'
                              )
                            : ''
                        "
                      >
                        {{
                          scope.$index == 0
                            ? infoList.V_SP.SP21
                            : scope.$index == 1 && infoList.V_RS2.SLSJ_S_B2 >= 0
                            ? infoList.V_RS2.SLSJ_S_B2
                            : scope.$index == 2
                            ? infoList.V_SOUREC.TE_13_B2
                            : scope.$index == 3
                            ? infoList.V_TJ2.FQXL_B2
                            : scope.$index == 4
                            ? infoList.V_RS2.YCFQWD_B2
                            : "0"
                        }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="number3" label="3号炉" align="center">
                    <template scope="scope">
                      <span
                        class="wzcolor1"
                        :style="
                          scope.$index == 0
                            ? 'color:#00b4ff;cursor: pointer;'
                            : ''
                        "
                        @click="
                          scope.$index == 0
                            ? toIpt(
                                infoList.V_SP.SP31,
                                '3号炉烧炉时间',
                                'SP31',
                                'V_SP'
                              )
                            : scope.$index == 2
                            ? toCompon(
                                2,
                                'TE_13_B3',
                                'V_SOUREC',
                                'TE_13_B3_WF',
                                '3#热风炉废气温度'
                              )
                            : scope.$index == 3
                            ? toCompon(
                                2,
                                'FQXL_B3',
                                'V_TJ3',
                                'FQXL_B3_WF',
                                '3#废气斜率'
                              )
                            : scope.$index == 4
                            ? toCompon(
                                2,
                                'YCFQWD_B3',
                                'V_RS3',
                                'YCFQWD_B3_WF',
                                '3#炉预测废气温度'
                              )
                            : ''
                        "
                        @dblclick="
                          scope.$index == 2
                            ? Cclick(
                                infoList.V_SOUREC.TE_13_B3,
                                'TE_13_B3',
                                'V_SOUREC',
                                'TE_13_B3_WF',
                                '3#热风炉废气温度'
                              )
                            : scope.$index == 3
                            ? Cclick(
                                infoList.V_TJ3.FQXL_B3,
                                'FQXL_B3',
                                'V_TJ3',
                                'FQXL_B3_WF',
                                '3#废气斜率'
                              )
                            : scope.$index == 4
                            ? Cclick(
                                infoList.V_RS3.YCFQWD_B3,
                                'YCFQWD_B3',
                                'V_RS3',
                                'YCFQWD_B3_WF',
                                '3#炉预测废气温度'
                              )
                            : ''
                        "
                      >
                        {{
                          scope.$index == 0
                            ? infoList.V_SP.SP31
                            : scope.$index == 1 && infoList.V_RS3.SLSJ_S_B3 >= 0
                            ? infoList.V_RS3.SLSJ_S_B3
                            : scope.$index == 2
                            ? infoList.V_SOUREC.TE_13_B3
                            : scope.$index == 3
                            ? infoList.V_TJ3.FQXL_B3
                            : scope.$index == 4
                            ? infoList.V_RS3.YCFQWD_B3
                            : "0"
                        }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="number4" label="4号炉" align="center">
                    <template scope="scope">
                      <span
                        class="wzcolor1"
                        :style="
                          scope.$index == 0
                            ? 'color:#00b4ff;cursor: pointer;'
                            : ''
                        "
                        @click="
                          scope.$index == 0
                            ? toIpt(
                                infoList.V_SP.SP41,
                                '4号炉烧炉时间',
                                'SP41',
                                'V_SP'
                              )
                            : scope.$index == 2
                            ? toCompon(
                                2,
                                'TE_13_B4',
                                'V_SOUREC',
                                'TE_13_B4_WF',
                                '4#热风炉废气温度'
                              )
                            : scope.$index == 3
                            ? toCompon(
                                2,
                                'FQXL_B4',
                                'V_TJ4',
                                'FQXL_B4_WF',
                                '4#废气斜率'
                              )
                            : scope.$index == 4
                            ? toCompon(
                                2,
                                'YCFQWD_B4',
                                'V_RS4',
                                'YCFQWD_B4_WF',
                                '4#炉预测废气温度'
                              )
                            : ''
                        "
                        @dblclick="
                          scope.$index == 2
                            ? Cclick(
                                infoList.V_SOUREC.TE_13_B4,
                                'TE_13_B4',
                                'V_SOUREC',
                                'TE_13_B4_WF'
                              )
                            : scope.$index == 3
                            ? Cclick(
                                infoList.V_TJ4.FQXL_B4,
                                'FQXL_B4',
                                'V_TJ4',
                                'FQXL_B4_WF'
                              )
                            : scope.$index == 4
                            ? Cclick(
                                infoList.V_RS4.YCFQWD_B4,
                                'YCFQWD_B4',
                                'V_RS4',
                                'YCFQWD_B4_WF'
                              )
                            : ''
                        "
                      >
                        {{
                          scope.$index == 0
                            ? infoList.V_SP.SP41
                            : scope.$index == 1 && infoList.V_RS4.SLSJ_S_B4 >= 0
                            ? infoList.V_RS4.SLSJ_S_B4
                            : scope.$index == 2
                            ? infoList.V_SOUREC.TE_13_B4
                            : scope.$index == 3
                            ? infoList.V_TJ4.FQXL_B4
                            : scope.$index == 4
                            ? infoList.V_RS4.YCFQWD_B4
                            : "0"
                        }}
                      </span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
        <div class="right_all">
          <div class="canshu" @click="toCompon(5)">公共参数</div>
          <div class="flex">
            <div class="rightmain">
              <div class="right-fm1">
                <img
                  src="../../assets/images/fam1.png"
                  style="width: 100%; height: 100%"
                />
              </div>
              <div class="right-fm2">
                <img src="../../assets/images/fam2.png" alt="" class="" />
              </div>
            </div>
            <div class="flex">
              <div
                class="lable4"
                @click="
                  toCompon(2, 'TE_00_B', 'V_SOUREC', 'TE_00_B_WF', '热风温度1')
                "
                @dblclick="
                  Cclick(
                    infoList.V_SOUREC.TE_00_B,
                    'TE_00_B',
                    'V_SOUREC',
                    'TE_00_B_WF'
                  )
                "
              >
                {{ infoList.V_SOUREC.TE_00_B }}℃
              </div>
              <div class="lable4" style="margin-left: 13vw">热风管道</div>
            </div>
          </div>
          <div class="biaoge3">
            <el-table
              :data="tableData1"
              border
              :header-cell-style="headerStyle"
              :row-style="{ height: '1.5vh', background: 'transparent' }"
              :cell-style="{ padding: '0px', borderColor: '#0f3747' }"
              style="width: 25vw; height: 16vh; background-color: transparent"
            >
              <el-table-column
                prop="index"
                label="炉号"
                align="center"
                width="70px"
                font-size="1.7vh"
              >
              </el-table-column>
              <el-table-column prop="number" label="基本空燃比" align="center">
                <!-- @click="scope.$index == 0 ? toIpt(infoList.V_SOUREC.JBFMB_B1,'1号基本空燃比','JBFMB_B1','V_SOUREC') : scope.$index == 2 ? toCompon(2,'TE_13_B4','V_SOUREC','TE_13_B4_WF') : scope.$index == 3 ? toCompon(2,'FQXL_B4','V_TJ4','FQXL_B4_WF') : scope.$index == 4 ? toCompon(2,'YCFQWD_B4','V_RS4','YCFQWD_B4_WF') : ''" -->
                <template scope="scope">
                  <span
                    class="wzcolor"
                    style="color: #1eb0f5; cursor: pointer; font-size: 1.7vh"
                    @click="
                      toIpt(
                        scope.$index == 0
                          ? infoList.V_SOUREC.JBFMB_B1
                          : scope.$index == 1
                          ? infoList.V_SOUREC.JBFMB_B2
                          : scope.$index == 2
                          ? infoList.V_SOUREC.JBFMB_B3
                          : scope.$index == 3
                          ? infoList.V_SOUREC.JBFMB_B4
                          : '',
                        scope.$index + 1 + '号基本空燃比',
                        'JBFMB_B' + (scope.$index + 1),
                        'V_SOUREC'
                      )
                    "
                  >
                    {{
                      scope.$index == 0
                        ? infoList.V_SOUREC.JBFMB_B1
                        : scope.$index == 1
                        ? infoList.V_SOUREC.JBFMB_B2
                        : scope.$index == 2
                        ? infoList.V_SOUREC.JBFMB_B3
                        : scope.$index == 3
                        ? infoList.V_SOUREC.JBFMB_B4
                        : ""
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="number1"
                label="优化空燃比"
                align="center"
                width=""
              >
                <template scope="scope">
                  <span
                    class="wzcolor"
                    @click="
                      toCompon(
                        2,
                        'YHFMB_B' + (scope.$index + 1),
                        'V_BCS' + (scope.$index + 1),
                        'YHFMB_B' + (scope.$index + 1) + '_WF'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList['V_BCS' + (scope.$index + 1)][
                          'YHFMB_B' + (scope.$index + 1)
                        ],
                        'YHFMB_B' + (scope.$index + 1),
                        'V_BCS' + (scope.$index + 1),
                        'YHFMB_B' + (scope.$index + 1) + '_WF'
                      )
                    "
                  >
                    {{
                      scope.$index == 0
                        ? infoList.V_BCS1.YHFMB_B1
                        : scope.$index == 1
                        ? infoList.V_BCS2.YHFMB_B2
                        : scope.$index == 2
                        ? infoList.V_BCS3.YHFMB_B3
                        : scope.$index == 3
                        ? infoList.V_BCS4.YHFMB_B4
                        : ""
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="number2" align="center" label="实际空燃比">
                <template scope="scope">
                  <span
                    class="wzcolor"
                    @click="
                      toCompon(
                        2,
                        'SJFMB_B' + (scope.$index + 1),
                        'V_BCS' + (scope.$index + 1),
                        'SJFMB_B' + (scope.$index + 1) + '_WF'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList['V_BCS' + (scope.$index + 1)][
                          'SJFMB_B' + (scope.$index + 1)
                        ],
                        'SJFMB_B' + (scope.$index + 1),
                        'V_BCS' + (scope.$index + 1),
                        'SJFMB_B' + (scope.$index + 1) + '_WF'
                      )
                    "
                  >
                    {{
                      scope.$index == 0
                        ? infoList.V_BCS1.SJFMB_B1
                        : scope.$index == 1
                        ? infoList.V_BCS2.SJFMB_B2
                        : scope.$index == 2
                        ? infoList.V_BCS3.SJFMB_B3
                        : scope.$index == 3
                        ? infoList.V_BCS4.SJFMB_B4
                        : ""
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="number3"
                align="center"
                label="煤气干预量"
                width=""
              >
                <template scope="scope">
                  <span
                    class="wzcolor"
                    style="color: #1eb0f5; cursor: pointer"
                    @click="
                      toIpt(
                        scope.$index == 0
                          ? infoList.V_SOUREC.GYMQL_B1
                          : scope.$index == 1
                          ? infoList.V_SOUREC.GYMQL_B2
                          : scope.$index == 2
                          ? infoList.V_SOUREC.GYMQL_B3
                          : scope.$index == 3
                          ? infoList.V_SOUREC.GYMQL_B4
                          : '',
                        scope.$index + 1 + '号煤气干预量',
                        'GYMQL_B' + (scope.$index + 1),
                        'V_SOUREC'
                      )
                    "
                  >
                    {{
                      scope.$index == 0
                        ? infoList.V_SOUREC.GYMQL_B1
                        : scope.$index == 1
                        ? infoList.V_SOUREC.GYMQL_B2
                        : scope.$index == 2
                        ? infoList.V_SOUREC.GYMQL_B3
                        : scope.$index == 3
                        ? infoList.V_SOUREC.GYMQL_B4
                        : ""
                    }}
                  </span>
                </template>
              </el-table-column>
            </el-table>
            <el-table
              :data="tableData1"
              border
              :header-cell-style="headerStyle"
              :row-style="{ height: '1.6vh', background: 'transparent' }"
              :cell-style="{ padding: '0px', borderColor: '#0f3747' }"
              style="
                width: 28vw;
                height: 20vh;
                background-color: transparent;
                margin-top: 3vh;
              "
            >
              <el-table-column
                prop="index"
                label="炉号"
                align="center"
                width="50px"
              >
              </el-table-column>
              <el-table-column
                label="煤气控制"
                align="center"
                style="padding-bottom: 1vh"
              >
                <el-table-column
                  prop="number1"
                  label="目标值"
                  align="center"
                  width=""
                >
                  <template scope="scope">
                    <span
                      class="wzcolor"
                      style=""
                      @click="
                        toCompon(
                          2,
                          'MQSP_B' + (scope.$index + 1),
                          'V_RS' + (scope.$index + 1),
                          'MQSP_B' + (scope.$index + 1) + '_WF'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList['V_RS' + (scope.$index + 1)][
                            'MQSP_B' + (scope.$index + 1)
                          ],
                          'MQSP_B' + (scope.$index + 1),
                          'V_RS' + (scope.$index + 1),
                          'MQSP_B' + (scope.$index + 1) + '_WF'
                        )
                      "
                    >
                      {{
                        scope.$index == 0
                          ? infoList.V_RS1.MQSP_B1
                          : scope.$index == 1
                          ? infoList.V_RS2.MQSP_B2
                          : scope.$index == 2
                          ? infoList.V_RS3.MQSP_B3
                          : scope.$index == 3
                          ? infoList.V_RS4.MQSP_B4
                          : ""
                      }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="number2"
                  label="测量值"
                  align="center"
                  width=""
                >
                  <template scope="scope">
                    <span
                      class="wzcolor"
                      style=""
                      @click="
                        toCompon(
                          2,
                          'PV',
                          'V_RS__p__RSFB' + (scope.$index + 1) + '__p__XK01',
                          'XK' +
                            (scope.$index == 0
                              ? 'A'
                              : scope.$index == 1
                              ? 'B'
                              : scope.$index == 2
                              ? 'C'
                              : scope.$index == 3
                              ? 'D'
                              : '') +
                            '01_PV_WF'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList[
                            'V_RS__p__RSFB' + (scope.$index + 1) + '__p__XK01'
                          ].PV,
                          'PV',
                          'V_RS__p__RSFB' + (scope.$index + 1) + '__p__XK01',
                          'XK' +
                            (scope.$index == 0
                              ? 'A'
                              : scope.$index == 1
                              ? 'B'
                              : scope.$index == 2
                              ? 'C'
                              : scope.$index == 3
                              ? 'D'
                              : '') +
                            '01_PV_WF'
                        )
                      "
                    >
                      {{
                        scope.$index == 0
                          ? infoList.V_RS__p__RSFB1__p__XK01.PV
                          : scope.$index == 1
                          ? infoList.V_RS__p__RSFB2__p__XK01.PV
                          : scope.$index == 2
                          ? infoList.V_RS__p__RSFB3__p__XK01.PV
                          : scope.$index == 3
                          ? infoList.V_RS__p__RSFB4__p__XK01.PV
                          : ""
                      }}
                    </span>
                  </template>
                </el-table-column>
              </el-table-column>

              <el-table-column label="空气控制" align="center">
                <el-table-column
                  prop="number3"
                  label="目标值"
                  align="center"
                  width=""
                >
                  <template scope="scope">
                    <span
                      class="wzcolor"
                      style=""
                      @click="
                        toCompon(
                          2,
                          'KQSP_B' + (scope.$index + 1),
                          'V_RS' + (scope.$index + 1),
                          'KQSP_B' + (scope.$index + 1) + '_WF'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList['V_RS' + (scope.$index + 1)][
                            'KQSP_B' + (scope.$index + 1)
                          ],
                          'KQSP_B' + (scope.$index + 1),
                          'V_RS' + (scope.$index + 1),
                          'KQSP_B' + (scope.$index + 1) + '_WF'
                        )
                      "
                    >
                      {{
                        scope.$index == 0
                          ? infoList.V_RS1.KQSP_B1
                          : scope.$index == 1
                          ? infoList.V_RS2.KQSP_B2
                          : scope.$index == 2
                          ? infoList.V_RS3.KQSP_B3
                          : scope.$index == 3
                          ? infoList.V_RS4.KQSP_B4
                          : ""
                      }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="number1"
                  align="center"
                  label="测量值"
                  width=""
                >
                  <template scope="scope">
                    <span
                      class="wzcolor"
                      style=""
                      @click="
                        toCompon(
                          2,
                          'PV',
                          'V_RS__p__RSFB' + (scope.$index + 1) + '__p__XK02',
                          'XK' +
                            (scope.$index == 0
                              ? 'A'
                              : scope.$index == 1
                              ? 'B'
                              : scope.$index == 2
                              ? 'C'
                              : scope.$index == 3
                              ? 'D'
                              : '') +
                            '02_PV_WF'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList[
                            'V_RS__p__RSFB' + (scope.$index + 1) + '__p__XK02'
                          ].PV,
                          'PV',
                          'V_RS__p__RSFB' + (scope.$index + 1) + '__p__XK02',
                          'XK' +
                            (scope.$index == 0
                              ? 'A'
                              : scope.$index == 1
                              ? 'B'
                              : scope.$index == 2
                              ? 'C'
                              : scope.$index == 3
                              ? 'D'
                              : '') +
                            '02_PV_WF'
                        )
                      "
                    >
                      {{
                        scope.$index == 0
                          ? infoList.V_RS__p__RSFB1__p__XK02.PV
                          : scope.$index == 1
                          ? infoList.V_RS__p__RSFB2__p__XK02.PV
                          : scope.$index == 2
                          ? infoList.V_RS__p__RSFB3__p__XK02.PV
                          : scope.$index == 3
                          ? infoList.V_RS__p__RSFB4__p__XK02.PV
                          : ""
                      }}
                    </span>
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table>
          </div>
          <div class="biaoge4">
            <div style="width: 28vw" class="flex">
              <div class="row1">煤气累积（w m³ )</div>
              <div class="row2">
                <div class="row2-1">今天</div>
                <div
                  @click="
                    toCompon(
                      2,
                      'MQLJ5',
                      'V_TJ',
                      'MQLJ5_WF',
                      '热风炉支管今日煤气总用量'
                    )
                  "
                  @dblclick="
                    Cclick(infoList.V_TJ.MQLJ5, 'MQLJ5', 'V_TJ', 'MQLJ5_WF')
                  "
                >
                  {{ infoList.V_TJ.MQLJ5 }}
                </div>
              </div>
              <div class="row2">
                <div class="row2-1">昨天</div>
                <div
                  @click="
                    toCompon(
                      2,
                      'MQLJ10',
                      'V_TJ',
                      'MQLJ10_WF',
                      '热风炉支管昨日煤气总用量'
                    )
                  "
                  @dblclick="
                    Cclick(infoList.V_TJ.MQLJ10, 'MQLJ10', 'V_TJ', 'MQLJ10_WF')
                  "
                >
                  {{ infoList.V_TJ.MQLJ10 }}
                </div>
              </div>
            </div>
            <div style="width: 28vw" class="flex">
              <div class="row1">风温统计</div>
              <div class="row2">
                <div class="row2-1">今天</div>
                <div
                  @click="
                    toCompon(2, 'JRFB', 'V_TJ', 'JRFB_WF', '今天热风温度均值')
                  "
                  @dblclick="
                    Cclick(infoList.V_TJ.JRFB, 'JRFB', 'V_TJ', 'JRFB_WF')
                  "
                >
                  {{ infoList.V_TJ.JRFB }}
                </div>
              </div>
              <div class="row2">
                <div class="row2-1">昨天</div>
                <div
                  @click="
                    toCompon(2, 'JRFZ', 'V_TJ', 'JRFZ_WF', '昨天热风温度均值')
                  "
                  @dblclick="
                    Cclick(infoList.V_TJ.JRFZ, 'JRFZ', 'V_TJ', 'JRFZ_WF')
                  "
                >
                  {{ infoList.V_TJ.JRFZ }}
                </div>
              </div>
            </div>
            <div style="width: 28vw" class="flex">
              <div class="row1">单位冷风消耗煤气量</div>
              <div
                class="row3"
                @click="toCompon(2, 'SFLJ_KHJ', 'V_TJ', 'SFLJ_KHJ_WF')"
                @dblclick="
                  Cclick(
                    infoList.V_TJ.SFLJ_KHJ,
                    'SFLJ_KHJ',
                    'V_TJ',
                    'SFLJ_KHJ_WF'
                  )
                "
                style="cursor: pointer"
              >
                {{ infoList.V_TJ.SFLJ_KHJ }}
              </div>
            </div>
          </div>
          <div class="righlast flex">
            <div
              class="bottom"
              @click="
                toCompon(2, 'TE_07_B', 'V_SOUREC', 'TE_07_B_WF', '送风冷风温度')
              "
              @dblclick="
                Cclick(
                  infoList.V_SOUREC.TE_07_B,
                  'TE_07_B',
                  'V_SOUREC',
                  'TE_07_B_WF'
                )
              "
            >
              {{ infoList.V_SOUREC.TE_07_B }} &nbsp;℃
            </div>
            <div
              class="bottom"
              style="margin-left: 1vw"
              @click="
                toCompon(
                  2,
                  'PIC_05_B',
                  'V_SOUREC',
                  'PIC_05_B_WF',
                  '送风冷风压力'
                )
              "
              @dblclick="
                Cclick(
                  infoList.V_SOUREC.PIC_05_B,
                  'PIC_05_B',
                  'V_SOUREC',
                  'PIC_05_B_WF'
                )
              "
            >
              {{ infoList.V_SOUREC.PIC_05_B }} &nbsp;&nbsp;Kpa
            </div>
            <div
              class="bottom"
              style="margin-left: 1vw"
              @click="
                toCompon(2, 'FT_02_B', 'V_SOUREC', 'FT_02_B_WF', '冷风总管流量')
              "
              @dblclick="
                Cclick(
                  infoList.V_SOUREC.FT_02_B,
                  'FT_02_B',
                  'V_SOUREC',
                  'FT_02_B_WF'
                )
              "
            >
              {{ infoList.V_SOUREC.FT_02_B }} &nbsp; m³/h
            </div>
            <div class="lable5">冷气管道</div>
          </div>
          <div class="righlast1 flex">
            <div style="width: 5vw">富氧流量</div>
            <div>
              <span
                @click="
                  toCompon(2, 'FT_03_B', 'V_SOUREC', 'FT_03_B_WF', '富氧流量')
                "
                @dblclick="
                  Cclick(
                    infoList.V_SOUREC.FT_03_B,
                    'FT_03_B',
                    'V_SOUREC',
                    'FT_03_B_WF'
                  )
                "
                >{{ infoList.V_SOUREC.FT_03_B }}&nbsp;m³/h</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <Manual
      :key="isIndex"
      v-if="isMshow"
      @sendStatus="isClose"
      :historyname="Manualname"
      :node="Manualnode"
      :Lkname="ManualAname"
      :titname="Manualtitname"
      :infoList="infoList"
    ></Manual>
    <Firstcontrol
      :infoList="infoList"
      :isIndex="isIndex"
      :historyname="Firstcontrolname"
      :titname="Firstcontroltitname"
      :node="Firstcontrolnode"
      v-if="isFshow"
      @sendStatus="isClose"
    ></Firstcontrol>
    <Historical
      v-if="isHshow"
      @sendStatus="isClose"
      :historyname="historyname"
      :node="nodename"
      :Lkname="Aname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
    <inputVal ref="inputVal" @getTreeData="DataJson(arguments)"></inputVal>
    <Observer
      v-if="isOshow"
      @sendStatus="isClose"
      :infoList="infoList"
      :historyname="Observername"
      :node="Observernode"
    ></Observer>
    <publicParam
      v-if="isPshow"
      @sendStatus="isClose"
      :infoList="infoList"
    ></publicParam>
    <Param01
      v-if="isPshow01"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Param01>
    <Param11
      v-if="isPshow11"
      @sendStatus="isClose"
      :infoList="infoList"
      :datatype="historyname"
    ></Param11>
    <Param21
      v-if="isPshow21"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Param21>
    <Param31
      v-if="isPshow31"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Param31>
    <Param41
      v-if="isPshow41"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Param41>
  </div>
</template>

<script>
import index from "./_index/index.js";
import Manual from "@/components/Manual.vue"; //手操器组件
//import Evaluate from "@/components/Evaluate.vue"; //运行评价组件
//import Security from "@/components/Security.vue"; //安全设置组件
import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
// import Switchpage from "@/components/Switchpage.vue"; //切换画面组件
import ParameterYh from "@/components/ParameterYh.vue"; //参数YH组件
import Historical from "@/components/Historical.vue"; //历史趋势
import inputVal from "@/components/inputVal.vue"; //输入框组件
import Observer from "@/components/Observer.vue"; //观测器模版组件
import Rsf from "@/components/RSF.vue"; //阮伺服组件
import publicParam from "@/components/Param.vue"; //公共参数组件
import Param01 from "@/components/Param01.vue"; //参数01组件
import Param11 from "@/components/Param11.vue"; //参数11组件
import Param21 from "@/components/Param21.vue"; //参数21组件
import Param31 from "@/components/Param31.vue"; //参数31组件
import Param41 from "@/components/Param41.vue"; //参数41组件
export default {
  name: "index",
  components: {
    Manual,
    Firstcontrol,
    ParameterYh,
    Historical,
    inputVal,
    Observer,
    Rsf,
    publicParam,
    Param01,
    Param11,
    Param21,
    Param31,
    Param41,
  },
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },

  data: () => {
    return {
      chName: "",
      headerStyle: {
        "font-weight": "500",
        "font-size": "1.5vh",
        height: "1vh",
        borderColor: "#0f3747",
        background: "#205365",
        padding: "0px",
        border: "none",
        color: "#5ca4a6",
      },
      spotArr: [],
      grouptime: null,
      Jrconst: false,
      JrSecurity: false,
      flag: 0,
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      isComShow: false,
      historyname: "",
      nodename: "",
      Observername: "",
      Observernode: "",
      Rsfname: "",
      Rsfnode: "",
      bodyHeight: "",
      Firstcontrolname: "",
      Firstcontrolnode: "",
      Firstcontroltitname: "",
      fullscreenLoading: true,
      isHshow: false,
      isOshow: false,
      isRshow: false,
      screenWidth: document.body.clientWidth,
      screeHeight: "",
      conHeight: "",
      leftHeight: "",
      leftcon: "",
      tabline: "",
      tabline1: "",
      tabstyle: "",
      tablist: "",
      tabdatawidth: "",
      tabdatawidth1: "",
      Aname: "",
      changValue: null,
      rightHeight: "",
      tableData: [
        {
          index: 1,
          number: "0",
          number1: "0",
          number2: "0",
        },
        {
          index: 2,
          number: "0",
          number1: "0",
          number2: "0",
        },
        {
          index: 3,
          number: "0",
          number1: "0",
          number2: "0",
        },
      ],
      tableData1: [
        {
          index: 1,
        },
        {
          index: 2,
        },
        {
          index: 3,
        },
        {
          index: 4,
        },
      ],
      tableData2: [
        {
          index: 1,
          number: "烧炉时间",
          number1: "120",
          number2: "120",
          number3: "120",
          number4: "140",
        },
        {
          index: 2,
          number: "剩余时间",
          number1: "000",
          number2: "000",
          number3: "000",
          number4: "000",
          number5: "000",
        },
        {
          index: 3,
          number: "废气温度(℃)",
          number1: "375.0",
          number2: "375.0",
          number3: "375.0",
          number4: "375.0",
        },
        {
          index: 4,
          number: "废气温升(℃)",
          number1: "0.56",
          number2: "0.56",
          number3: "0.56",
          number4: "0.56",
        },
        {
          index: 5,
          number: "废气烟温(℃)",
          number1: "000",
          number2: "000",
          number3: "000",
          number4: "000",
          number5: "000",
        },
      ],
    };
  },

  mixins: [index],
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
    this.$bus.$on("sendMsg", (msg) => {
      // 底部组件发送来的消息
      this.flag = msg;
      if (this.flag == 3) {
        this.$router.push({
          path: "/switchPage",
        });
      } else if (this.flag == 0) {
        this.$router.push({
          path: "/index",
        });
      } else if (this.flag == 1) {
        this.$router.push({
          path: "/Security",
        });
      } else if (this.flag == 2) {
        this.$router.push({
          path: "/Evaluate",
        });
      }
    });
  },
  computed: {},
  mounted() {},
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  methods: {
    // 打开趋势组图页面
    qsGroup() {
      this.$router.push({
        path: "/trendGroup",
      });
    },
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
    // 打开下置输入窗口
    toIpt(data, name, historyname, node, type) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无操作权限");
        }
      } else {
        this.$refs.inputVal.open(data, name, historyname, node, type);
      }
    },
    // 打开历史趋势窗口
    toHistory(data) {
      this.$refs.history.open(data);
    },
  },
  filters: {
    // 图片过滤器
    typeFifter: (val) => {
      if (val < 5) {
        return require("../../assets/images/rts_bigimg3_end.png");
      } else if (val > 5) {
        return require("../../assets/images/rts_bigimg3_start.png");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.rqlindex-conitaner {
  width: 100%;
  height: 100%;
  margin-left: 0vw;
  overflow-x: hidden;
  overflow-y: hidden;

  .maskbok {
    width: 100vw;
    height: 100vh;
    margin-left: 0vw;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 99;
  }

  .title {
    width: 58vw;
    margin-left: 36vw;
    margin-top: 1vh;
    // margin-bottom: 14vh;
    height: 4vh;
    font-family: MicrosoftYaHei;
    font-size: 3.5vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 4vh;
    letter-spacing: 0vw;
    color: #ffffff;
    box-shadow: 0vw 0vw 0vw 0vw #009ed2;
  }

  .main {
    width: 97vw;
    height: 72vh;
    font-size: 1.7vh;
    margin-top: 7vh;
    // color: cornsilk;
    margin-left: 1vw;
    background-image: url("~@/assets/images/RflBoiler/Rflex.png");
    background-size: 100% 100%;

    .left_all {
      width: 60vw;
      height: 72vh;
      // background-color: red;

      .left_up {
        .lable1 {
          width: 5vw;
          margin-left: 5vh;
          margin-top: 3vh;
          height: 2vh;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2vh;
          letter-spacing: 0vw;
          color: #fefefe;
          font-size: 2vh;
        }

        .jiange1 {
          margin-left: 0vw;
          margin-top: 3.3vh;
          margin-bottom: 0.5vh;
        }

        .anfa {
          .leftx1 {
            margin-left: -1vw;
          }
        }

        .hd {
          width: 5vw;
          height: 3vh;
          font-size: 2vh;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2vh;
          letter-spacing: 0vh;
          color: #5ca4a6;
          margin-top: -2vh;
          margin-left: -4vw;
        }

        .gdpart {
          width: 25vw;
          margin-top: -6.7vh;
          margin-left: 2vw;

          .htwd {
            color: #5ca4a6;
            width: 6.5vw;
            line-height: 3.5vh;
            font-size: 2vh;
          }

          .htwdtitle {
            // margin-left: 31.0vw;
            margin-top: 0.5vh;
            font-size: 1.7vh;
            font-weight: normal;
            font-stretch: normal;
            line-height: 1vw;
            letter-spacing: 0vw;
            color: #d9feff;
          }
        }

        .jiange2 {
          margin-left: 2vw;
        }

        .jiange4 {
          margin-left: 4.5vw;
        }

        .jiange5 {
          margin-left: 4.5vw;
        }

        .jiange6 {
          margin-left: 4.5vw;
        }
      }

      .left_middle {
        width: 60vw;
        height: 10vh;
        margin-left: 13vw;
        margin-top: -2.2vh;

        .left_button {
          margin-left: 16.5vw;
          margin-top: -6.5vh;
        }

        .jiange4 {
          margin-left: 4.5vw;
        }

        .jiange5 {
          margin-left: 4.7vw;
        }

        .jiange6 {
          margin-left: 4.7vw;
        }
      }

      .left_down {
        .wzcolor1 {
          font-size: 1.7vh;
          font-weight: normal;
          font-stretch: normal;
          line-height: 3vh;
          letter-spacing: 0vh;
          color: #d9feff;
          cursor: pointer;
        }

        .htwdtitle {
          margin-left: 31vw;
          margin-top: 0.5vh;
          font-size: 1.7vh;
          font-weight: normal;
          font-stretch: normal;
          line-height: 1vw;
          letter-spacing: 0vw;
          color: #d9feff;
        }

        .left_left {
          .lable2 {
            width: 5vw;
            margin-left: 7vh;
            margin-top: 3.9vh;
            margin-bottom: 1vh;
            height: 2vh;
            // font-family: PingFang-SC-Regular;
            font-weight: normal;
            font-stretch: normal;
            line-height: 2vh;
            letter-spacing: 0vw;
            color: #fefefe;
            font-size: 2vh;
          }

          .lable3 {
            width: 5vw;
            margin-left: 2vh;
            margin-top: 8vh;
            margin-bottom: 1vh;
            height: 2vh;
            // font-family: PingFang-SC-Regular;
            font-weight: normal;
            font-stretch: normal;
            line-height: 2vh;
            letter-spacing: 0vw;
            color: #fefefe;
            font-size: 2vh;
          }

          .fwz1 {
            margin-left: 3vw;
            margin-top: 6vh;
          }

          .fwz2 {
            margin-left: 8.5vw;
            margin-top: 6vh;
          }

          .xinx5 {
            margin-left: 2.5vw;
            margin-top: 5vh;
          }

          .xinx6 {
            margin-left: 4vw;
            margin-top: 5vh;
          }
        }

        .left_right {
          .titlable {
            width: 7vw;
            height: 3vh;
            font-size: 2vh;
            font-weight: normal;
            font-stretch: normal;
            line-height: 3vh;
            letter-spacing: 0vh;
            color: #d9feff;
          }
        }
      }
    }

    .right_all {
      width: 30vw;
      height: 80vh;
      margin-top: -8vh;
      margin-left: 5vw;
      // background-color:white;

      .canshu {
        margin-left: 23vw;
        margin-top: 2vh;
        width: 7vw;
        height: 3vh;
        line-height: 3vh;
        background-color: #0a1417;
        border-radius: 0vw;
        opacity: 0.56;
        text-align: center;
        font-size: 1.8vh;
        cursor: pointer;
        color: white;
      }

      .right-fm1 {
        margin-left: 2vw;
        margin-top: 6vh;
        width: 2vw;
        height: 4vh;
      }

      .right-fm2 {
        margin-left: 3vw;
        margin-top: 1vh;
        width: 2vw;
        height: 4vh;
      }

      .lable4 {
        width: 5vw;
        margin-left: 3vh;
        margin-top: 5.5vh;
        margin-bottom: 1vh;
        height: 2vh;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2vh;
        letter-spacing: 0vw;
        color: #fefefe;
        font-size: 2vh;
      }

      .biaoge3 {
        margin-left: 5vw;
        margin-top: -5.2vh;
        font-size: 1.6vh;
        font-weight: normal;
        font-stretch: normal;
        line-height: 3vh;
        letter-spacing: 0vh;
        color: #5ca4a6;
      }

      .biaoge4 {
        width: 28vw;
        height: 14vh;
        margin-left: 5vw;
        margin-top: 3vh;
        font-size: 1.7vh;
        font-weight: normal;
        font-stretch: normal;
        line-height: 3vh;
        letter-spacing: 0vh;
        color: #5ca4a6;

        .row1 {
          width: 10vw;
          line-height: 4vh;
          background-color: #205365;
          margin-right: 0.1vw;
          text-align: center;
        }

        .row3 {
          width: 16.3vw;
          line-height: 4vh;
          border: 1px solid#0d3634;
          margin-right: 0.1vw;
          text-align: center;
          color: #d9feff;
        }

        .row2 {
          width: 8vw;
          line-height: 2.1vh;
          // background-color: #205365;
          margin-right: 0.1vw;
          text-align: center;
          border: 1px solid#0d3634;
          color: #d9feff;

          div {
            cursor: pointer;
          }
        }

        .row2-1 {
          line-height: 2.2vh;
          color: #d9feff;
          cursor: none;
        }
      }

      .righlast {
        width: 30vw;
        height: 3vh;
        font-size: 1.7vh;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2vh;
        letter-spacing: 0vh;
        color: #d9feff;
        margin-left: 8vw;
        margin-top: 3vh;

        .lable5 {
          width: 5vw;
          margin-left: 3vh;
          margin-top: 0vh;
          margin-bottom: 1vh;
          height: 2vh;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2vh;
          letter-spacing: 0vw;
          color: #fefefe;
          font-size: 2vh;
        }
      }

      .righlast1 {
        width: 30vw;
        height: 3vh;
        font-size: 1.7vh;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2vh;
        letter-spacing: 0vh;
        color: #d9feff;
        margin-left: 8vw;
        margin-top: 1vh;
      }

      .bottom {
        cursor: pointer;
      }
    }
  }

  .wzcolor {
    font-size: 1.7vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 3vh;
    letter-spacing: 0vh;
    color: #d9feff;
    cursor: pointer;
  }

  .wzcolor1 {
    font-size: 1.7vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 3vh;
    letter-spacing: 0vh;
    color: #d9feff;
    cursor: pointer;
  }

  .famen1 {
    width: 2vw;
    height: 5vh;
    cursor: pointer;
  }

  .famen2 {
    width: 2.5vw;
    height: 5vh;
  }

  .button {
    width: 1vw;
    height: 2vh;
    text-align: center;
    line-height: 2vh;
    border: 2px solid rgb(217, 243, 145);
    font-size: 1.7vh;
  }

  .lefttext {
    width: 4vw;
    height: 3vh;
    line-height: 3vh;
    font-size: 1.7vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 3vh;
    letter-spacing: 0vh;
    color: #d9feff;

    div {
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .hjg {
    line-height: 2.5vh;
    cursor: pointer;
  }

  .lefttext1 {
    margin-left: 8vw;
    margin-top: 7vh;
    width: 7vw;
    height: 6vh;
    font-size: 1.7vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vh;
    letter-spacing: 0vh;
    color: #d9feff;
  }

  ::v-deep {
    .el-table__body {
      border-left: 1px solid rgb(15, 55, 71);
    }
  }

  ::v-deep .el-table tbody tr:hover > td {
    background-color: transparent;
  }

  //去掉最下面的那一条线
  .el-table::before {
    height: 0px;
  }

  .customer-table .el-table__fixed-right::before,
  .el-table__fixed::before {
    width: 0;
  }

  // 表格最外边框
  .el-table--border,
  .el-table--group {
    border: none;
  }

  .customer-table::before {
    width: 0;
  }

  .customer-table .el-table__fixed-right::before,
  .el-table__fixed::before {
    width: 0;
  }

  .el-table--border::after,
  .el-table--group::after {
    width: 0;
  }

  .customer-table td,
  .customer-table th.is-leaf {
    border: none;
  }
}
</style>
